import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Form, Overlay, Popover, Table } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { usePlacesWidget } from "react-google-autocomplete";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import ImageCropModal from "components/Input/ImageCropModal";
import { TIMEZONE_OPT, PROFILE_IMAGE_PATH, EXTRERNAL_IMAGE_PATH } from "constant";
import {
  validatePhone,
  isValidEmail,
  convertToLower,
  formatZip,
  getValidDep,
  getValidReg,
  getValidSpaces,
  formatZipCode,
  toTitleCase,
  searchPhone,
  checkValidity,
  CONFIG,
} from "../../utils";
import moment from "moment";
import api from "api";
import { Storage } from "aws-amplify";
import { AppContext } from "context/app-context";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PhoneNoInput from "../Phone/PhoneNoInput";
import Default_Profile from "assets/img/default_profile.png";
import countriesOption from "countries";
import EditableDropDown from "components/Dropdown/EditableDropDown";
import CustomDropDown from "components/Dropdown/CustomDropDown";
import localnoOptions from "localno";
import QrCodeCard from "components/QrCard/QrCodeCard";
import QrPrint from "components/QrCard/QrPrint";
import ImageInput from "components/Input/ImageInput";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import Loader from "components/Loader/Loader";
import RadioButton from "../RadioButton/RadioButton";
import ColorDropDown from "components/Dropdown/ColorDropDown";
import { IMAGE_UPLOAD_TYPE } from "constant";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import ProfileRelationModal from "components/Modal/ProfileRelationModalonModal";
import { id } from "date-fns/locale";
const options = {
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EmployeeModal = (props) => {
  const { user, handleSave, handleClose, program, cssClass, eosModal } = props;
  const appContext = useContext(AppContext);
  const [showProfileModal, setProfileModal] = useState(false);
  const [connected, setConnected] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [newUser, setNewUser] = useState(user);
  const [address, setAddress] = useState(null);
  const [show, setShow] = useState("");
  const [locations, setLocations] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [regions, setRegions] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [showPopOver, setShowPopOver] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [boosterImage, setBoosterImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedVal, setSelectedVal] = useState(user.isNew ? user.department : "");
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [qrPrint, setQrPrint] = useState(false);
  const [expandReltionList, setExpandRelationList] = useState(false);
  const [randomImage, setRandomImage] = useState();
  const firstNameRef = useRef();
  const childNode = useRef();
  useEffect(() => {
    getConnectedProfiles(user?.connected);
  }, [user]);
  useEffect(async () => {
    setNewUser(user);
    if (!user.isNew && user.picture) {
      setProfileImage(`${PROFILE_IMAGE_PATH}${encodeURIComponent(user.picture)}`);
    }
    if (user.isVaccinated && user.vaccinationFile) {
      await getFileFromStorage(user.vaccinationFile, setVaccinationImage);
    }
    if (user.boosterFile && user.isBooster) {
      await getFileFromStorage(user.boosterFile, setBoosterImage);
    }
  }, [user]);

  useEffect(() => {
    if (selectedVal) {
      const calltime = departments.find((dpt) => selectedVal === dpt.id)?.callTime || "";
      const timeZone = departments.find((dpt) => selectedVal === dpt.id)?.deptTimeZone || null;
      setTimeZone(timeZone);
      const obj = { ...newUser, callTime: calltime };
      setNewUser(obj);
    }
  }, [selectedVal, departments]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    if (user.link) {
      setRandomImage(require(`assets/img/faces/${user.link}.jpg`).default);
    }
  }, [user]);

  useEffect(() => {
    getRegionData();
    getDepartments();
    setLocations(appContext.locations);
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (timeZone === null || timeZone) {
      const obj = { ...newUser, empTZ: "" };
      setNewUser(obj);
    }
  }, [timeZone]);
  useEffect(() => {
    if (address) {
      setNewUser({ ...newUser, ...address });
    }
  }, [address]);

  const getFileFromStorage = async (file, setPath) => {
    try {
      const path = await Storage.get(file, { bucket: "employee-external-tests" });
      setPath(path);
      console.log("path", path);
    } catch {
      console.log("Image loading fail");
    }
  };

  const handleAddressUpdate = (place) => {
    if (!place || Object.keys(place).length === 1) return;

    const obj = {};
    let address1 = "";
    let postcode = "";
    for (const curr of place.address_components) {
      const type = curr.types[0];

      switch (type) {
        case "street_number": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "premise": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "plus_code": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "neighborhood": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_4": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_3": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_2": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "route": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "postal_code": {
          postcode = `${curr.long_name}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${curr.long_name}`;
          break;
        }
        case "postal_town": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "locality": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "administrative_area_level_1": {
          Object.assign(obj, { city: obj.city || curr.long_name, state: curr.short_name });
        }
        case "country": {
          Object.assign(obj, { country: curr.long_name });
          break;
        }
      }
    }
    Object.assign(obj, { street: address1.trim() || place.name, zip: postcode });
    setAddress({ ...obj });
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",
    onPlaceSelected: (place) => {
      handleAddressUpdate(place);
    },
    options,
  });

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };
  const getRegionData = async () => {
    const data = await api.getRegions();
    setRegions(data);
  };

  const onHandleSave = async (isConfirm, newUser) => {
    if (
      isConfirm &&
      newUser.callTime !== user.callTime &&
      newUser.isSchedule === 1 &&
      appContext.company?.programLevel !== "Zone"
    ) {
      setOpenConfirmation(true);
      return;
    }

    if (!newUser.firstName || newUser.firstName === " ") {
      setShow("First name is required");
      return;
    }

    if (!newUser.lastName || newUser.lastName === " ") {
      setShow("Last name is required");
      return;
    }

    if (!newUser.email || newUser.email === " ") {
      setShow("Email name is required");
      return;
    }

    if (!isValidEmail(newUser.email)) {
      setShow("Invalid Email");
      return;
    }

    if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
      setShow("Phone number is required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setShow("Invalid Phone Number");
      return;
    }

    // if (!newUser.gender) {
    //   setShow("Required gender");
    //   return;
    // }

    // if (!newUser.location || newUser.location === " ") {
    //   setShow("Office location is required");
    //   return;
    // }
    // if (!newUser.region || newUser.region === " ") {
    //   setShow("Region is required");
    //   return;
    // }

    // if (newUser.programName && !newUser.department?.trim()) {
    //   setShow("Department is required");
    //   return;
    // }
    const findVal = await appContext.employees.find((curr) => {
      if (newUser.dob) {
        return (
          checkValidity(newUser.firstName) === checkValidity(curr.firstName) &&
          checkValidity(newUser.lastName) === checkValidity(curr.lastName) &&
          newUser.phoneNumber === curr.phoneNumber &&
          newUser.email === curr.email &&
          newUser.dob === curr.dob
        );
      } else {
        return (
          checkValidity(newUser.firstName) === checkValidity(curr.firstName) &&
          checkValidity(newUser.lastName) === checkValidity(curr.lastName) &&
          newUser.phoneNumber === curr.phoneNumber &&
          newUser.email === curr.email
        );
      }
    });

    if ((findVal && newUser.id && newUser.id !== findVal.id) || (findVal && newUser.isNew)) {
      setShow("Employee  already exists!");
      return;
    }
    if (newUser.department && !newUser.callTime) {
      newUser.callTime = departments.find((dpt) => newUser.department === dpt.id)?.callTime || "";
    }
    if (newUser.department && !newUser.callTime) {
      setShow(`Please add Call Time ${newUser.departmentName} Department in settings`);
      return;
    }
    if (newUser.empTZ) {
      const deptTimeZone = departments.find((dpt) => newUser.department === dpt.id)?.deptTimeZone || "";
      if (newUser.empTZ === deptTimeZone) newUser.empTZ = "";
    }
    const phone = formatPhoneNumberIntl(newUser.phoneNumber);
    const phoneArr = phone.split(" ");
    newUser.countryCode = phoneArr[0];
    if (profileImage instanceof Object) {
      Object.assign(newUser, { ...newUser, profileImage: profileImage });
    }
    if (newUser.programID && appContext.isZoneLevelCompany()) {
      Object.assign(newUser, { ...newUser, isSchedule: 3 });
    }
    if (!newUser.isVaccinated) {
      Object.assign(newUser, { isBooster: false });
    }

    if (vaccinationImage instanceof Object && newUser.isVaccinated) {
      let vaccinationFileName = await api.saveFileExternalStorage(
        newUser.phoneNumber,
        IMAGE_UPLOAD_TYPE.VacinationCard,
        vaccinationImage
      );
      Object.assign(newUser, { vaccinationFile: vaccinationFileName });
    }
    if (boosterImage instanceof Object && newUser.isBooster) {
      let boosterFileName = await api.saveFileExternalStorage(
        newUser.phoneNumber,
        IMAGE_UPLOAD_TYPE.BoosterImage,
        boosterImage
      );
      Object.assign(newUser, { boosterFile: boosterFileName });
    }
    if (eosModal) {
      handleSaveUser(newUser);
    } else {
      handleSave(newUser);
      handleClose();
    }
  };

  const handleSaveUser = async (user) => {
    try {
      setLoading(true);
      if (user.location) await api.newLocations(user.location, appContext.user);
      if (user.region) await api.newRegions(user.region, appContext.user);

      if (user.isNew) {
        const result = await api.newEmployee(user, null, appContext.user);

        if (result) {
          if (user.isApp) await handleCreateSystemUSer(result);
          handleSave(user, result);
          handleClose();
        }
      } else {
        await api.updateEmployee(user, appContext.user);
        if (user.isApp) await handleCreateSystemUSer(user);

        handleSave(user);
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      appContext.showErrorMessage(error.message);
    }
  };

  const handleCreateSystemUSer = async (user) => {
    try {
      const res = await api.createSystemUser({ ...user, password: "Pass1234!" });
      console.log("res", res);
      if (res) {
        await api.updateEmployeeSub(user.id, res.userSub);
      }
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const handleCropDone = (isConfirm, fileData) => {
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const handleProgramChange = (obj) => {
    setNewUser({ ...newUser, programID: obj.id, programName: obj.name });
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const obj = {
      ...newUser,
      ...record,
      firstName: record.first,
      lastName: record.last,
      isNew: true,
      gender: record.sex,
      phoneNumber: searchPhone(record.phone_number),
      country: record.country ? record.country : "United States",
      idNumber: record.id_number,
    };
    setNewUser(obj);
  };
  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    if (phoneNo && type === "Phone") {
      const phone = formatPhoneNumberIntl(data);
      const phoneArr = phone.split(" ");
      phoneArr.shift();
      phoneNo = phoneArr.join("");
    }
    let env = CONFIG.isLive ? "remote" : "localhost";

    let record = [];
    try {
      record = await api.getPreRegisterData(phoneNo, type, env);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }

    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(
        record.map((e) => {
          return { ...e, first: toTitleCase(e.first), last: toTitleCase(e.last) };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      const obj = { ...newUser, callTime: user.callTime, onAlert: true };
      onHandleSave(false, obj);
    } else {
      onHandleSave(false, newUser);
    }
  };
  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };
  const getTimeZone = () => {
    const str = newUser.empTZ || timeZone;
    if (!str) return null;
    return { value: str, label: str };
  };

  const handleNewItem = (item) => {
    const arr = [...departments, item];
    setDepartments(arr);
  };

  const getConnectedProfiles = async () => {
    let response = await api.getlatestEmployee(newUser.id);
    if (response) {
      let res = await api.getRelatedEmployee(response.connectedTo);
      const resObj = res?.map((rel) => {
        return {
          ...rel,
          manageAs:
            rel.connectedTo?.find((r) => r.relation === rel.relation)?.relationType === "Parent"
              ? "Main Contact"
              : "Sub Contact",
        };
      });
      setConnected(resObj);
      // appContext.resetEmployees();
    }
  };

  const getEmployeeDepartment = () => {
    if (newUser.department) {
      const dept = departments.find((dpt) => newUser.department === dpt.id);
      if (dept) return { value: dept.id, label: dept.name };
      return "";
    }
    return "";
  };

  const handleQrPrint = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };
  const makeRelationModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setProfileModal(!showProfileModal);
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="xl"
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        className={`${cssClass}`}
        style={{ paddingLeft: "0" }}
        centered
        keyboard={true}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create Employee" : "Update Employee"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="leftSide">
              {!newUser.isNew && (
                <>
                  <div className="upload-file-section d-flex justify-content-between">
                    <ImageInput
                      type="profile"
                      setSelectedImage={setProfileImage}
                      selectedImage={profileImage || randomImage}
                      showCropDialog={setShowImageCropper}
                      setCopyImage={setCopyImage}
                    />
                    <div className="badges-button-wrapper">
                      <button
                        className="headerButton btn-fill btn btn-secondary mr-0"
                        onClick={() => {
                          setQrPrint(false), handleQrPrint();
                        }}
                      >
                        Print ID Badge
                      </button>
                      {/* <button
                        className="headerButton btn-fill btn btn-secondary mr-0 print-qr-button"
                        onClick={() => {
                          setQrPrint(true), handleQrPrint();
                        }}
                      >
                        Print QR
                      </button> */}
                    </div>
                  </div>
                </>
              )}
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">First Name: </label>
                  <input
                    ref={firstNameRef}
                    value={newUser.firstName}
                    className="modalInput"
                    placeholder="First Name"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, firstName: getValidDep(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Last Name: </label>
                  <input
                    value={newUser.lastName}
                    className="modalInput"
                    placeholder="Last Name"
                    onChange={(e) => setNewUser({ ...newUser, lastName: getValidDep(e.target.value) })}
                  />
                </div>
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">DOB: </label>
                <DatePicker
                  className="w-100 employee-dob"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  format="MM/dd/y"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                    })
                  }
                  value={
                    newUser.dob && new Date(newUser.dob) != "Invalid Date"
                      ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                      : null
                  }
                  maxDate={new Date()}
                  minDate={new Date("1900-01-01")}
                />
              </div>
              <div className="form-group-wrapper position-relative">
                <label className="modalLineHeaders">Email: </label>
                <input
                  value={newUser.email}
                  className={`modalInput ${newUser.isNew && "pd-right-30"}`}
                  placeholder="Email"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, email: convertToLower(e.target.value) })}
                />
                {newUser.isNew && (
                  <i
                    ref={childNode}
                    className="fas fa-search cursor-pointer"
                    onClick={() => searchPreReg(newUser.email, "Email")}
                  />
                )}
              </div>

              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone #: </label>
                <PhoneNoInput
                  cssClass={newUser.isNew && "pd-right-30"}
                  handleChange={handlePhoneNo}
                  value={newUser.phoneNumber}
                />
                {newUser.isNew && (
                  <i
                    ref={childNode}
                    className="fas fa-search cursor-pointer"
                    onClick={() => searchPreReg(newUser.phoneNumber, "Phone")}
                  />
                )}
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Job Title: </label>
                <input
                  value={newUser.jobTitle}
                  className="modalInput"
                  placeholder="Job Title"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, jobTitle: getValidSpaces(e.target.value) })}
                />
              </div>
              <div className="form-group-wrapper emp-select-area">
                <label className="modalLineHeaders emp-gender-mb">Gender: </label>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <RadioButton
                    name="gender"
                    id="male"
                    label="Male"
                    checked={newUser?.gender === "M" ? true : false}
                    handleChange={(e) => setNewUser({ ...newUser, gender: e.target.checked ? "M" : "" })}
                  />
                  <RadioButton
                    name="gender"
                    id="female"
                    checked={newUser?.gender === "F" ? true : false}
                    label="Female"
                    handleChange={(e) => setNewUser({ ...newUser, gender: e.target.checked ? "F" : "" })}
                  />
                  <RadioButton
                    id="other"
                    checked={newUser?.gender === "X" ? true : false}
                    name="gender"
                    label="Not Specified"
                    handleChange={(e) => setNewUser({ ...newUser, gender: e.target.checked ? "X" : "" })}
                  />
                </div>
              </div>
              {!appContext.isReadOnly() && (
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">{program}s: </label>
                  <ColorDropDown
                    title={`Select ${program}`}
                    data={appContext.programs}
                    selectedOption={newUser.programID}
                    onOptionChange={handleProgramChange}
                  />
                </div>
              )}
            </div>
            <div className="rightSide">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Address: </label>
                  <input
                    ref={ref}
                    value={newUser.street}
                    placeholder="Enter Address"
                    className="modalInput"
                    onChange={(e) => setNewUser({ ...newUser, street: getValidDep(e.target.value) })}
                  />
                  {/* <input
                value={newUser.street}
                ref={ref}
                className="modalInput"
                placeholder="Address"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setNewUser({ ...newUser, street: toTitleCase(e.target.value) })}
              /> */}
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Address 2: </label>
                  <input
                    value={newUser.street2}
                    placeholder="Enter Address 2"
                    className="modalInput"
                    onChange={(e) => setNewUser({ ...newUser, street2: getValidDep(e.target.value) })}
                  />
                </div>
              </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">City: </label>
                  <input
                    value={newUser.city}
                    className="modalInput"
                    placeholder="City"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, city: getValidDep(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">State: </label>
                  <input
                    value={newUser.state}
                    className="modalInput"
                    placeholder="State"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) val = val.toUpperCase();
                      setNewUser({ ...newUser, state: val });
                    }}
                  />
                </div>
              </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Zip Code: </label>
                  <input
                    type="text"
                    value={newUser.zip}
                    className="modalInput"
                    placeholder="XXXXX-XXXX"
                    maxLength={10}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, zip: formatZipCode(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Country: </label>

                  <Select
                    options={countriesOption}
                    blurInputOnSelect={true}
                    defaultValue={{ label: "United States", value: "United States" }}
                    menuPlacement="auto"
                    placeholder="Select Country"
                    className="w-100"
                    value={newUser.country ? { label: newUser.country, value: newUser.country } : null}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        country: e.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Location: </label>
                  <CustomDropDown
                    data={locations.map((t) => {
                      return { value: getValidReg(t.name), label: getValidReg(t.name) };
                    })}
                    title="Select Location"
                    selectedOption={newUser.location}
                    onOptionChange={(val) => setNewUser({ ...newUser, location: val.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Region: </label>
                  <CustomDropDown
                    data={regions.map((t) => {
                      return { value: getValidReg(t.name), label: getValidReg(t.name) };
                    })}
                    title="Select Region"
                    selectedOption={newUser.region}
                    onOptionChange={(val) => setNewUser({ ...newUser, region: val.value })}
                  />
                </div>
              </div>

              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Call Time: </label>
                  <input
                    type="time"
                    id="callTime"
                    value={newUser.callTime}
                    className="modalInput align-middle"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, callTime: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Department: </label>
                  {/* <EditableDropDown
                data={departments.map((t) => {
                  return { value: t.id, label: toTitleCase(t.name) };
                })}
                title="Select Department"
                selectedOption={newUser.department}
                onOptionChange={(val) => setNewUser({ ...newUser, department: val.value })}
                setSelectedVal={setSelectedVal}
                addNewItem={handleNewItem}
              /> */}
                  <Select
                    options={departments.map((t) => {
                      return { value: t.id, label: getValidReg(t.name) };
                    })}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Department"
                    className="w-100"
                    value={getEmployeeDepartment()}
                    onChange={(val) => {
                      setSelectedVal(val.value);
                      setNewUser({ ...newUser, department: val.value, departmentName: val.label });
                    }}
                  />
                </div>
              </div>
              {/* <div className="form-group-wrapper">
                <label className="modalLineHeaders">Time Zone: </label>
                <Select
                  options={TIMEZONE_OPT}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select TimeZone"
                  className="w-100"
                  value={getTimeZone()}
                  onChange={(e) => setNewUser({ ...newUser, empTZ: e.value })}
                />
              </div> */}
              <div className={!newUser.isNew ? "first-last-name-wrapper" : ""}>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">License/SSN: </label>
                  <input
                    value={newUser.idNumber}
                    className="modalInput"
                    maxLength={16}
                    placeholder="ID Number"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, idNumber: e.target.value })}
                  />
                </div>
                {!newUser.isNew && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">SCHR ID: </label>
                    <input
                      value={newUser.schrID}
                      readOnly
                      disabled
                      className="modalInput"
                      placeholder="schrID Number"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="first-last-name-wrapper mb-3">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders emp-gender-mb">Vaccinated: </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <RadioButton
                      name="isVaccinated"
                      id="vaccinated-yes"
                      checked={newUser.isVaccinated}
                      label="Yes"
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          isVaccinated: e.target.checked && true,
                        })
                      }
                    />
                    <RadioButton
                      label="No"
                      name="isVaccinated"
                      id="vaccinated-no"
                      checked={!newUser.isVaccinated}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          isVaccinated: e.target.checked && false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders emp-gender-mb">Union Member: </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <RadioButton
                      label="Yes"
                      name="Union"
                      id="union-yes"
                      checked={newUser.unionNo}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          unionNo: e.target.checked && true,
                        })
                      }
                    />
                    <RadioButton
                      label="No"
                      name="Union"
                      id="union-no"
                      checked={!newUser.unionNo}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          unionNo: e.target.checked && false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className={newUser.isVaccinated ? "first-last-name-wrapper mb-3" : ""}>
                {newUser.isVaccinated && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders emp-gender-mb">Booster: </label>
                    <div className="d-flex align-items-center justify-content-between">
                      <RadioButton
                        name="isBooster"
                        id="isBooster-yes"
                        checked={newUser.isBooster}
                        label="Yes"
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isBooster: e.target.checked && true,
                          })
                        }
                      />
                      <RadioButton
                        label="No"
                        name="isBooster"
                        id="isBooster-no"
                        checked={!newUser.isBooster}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isBooster: e.target.checked && false,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                {newUser.unionNo && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Local #: </label>
                    <Select
                      options={localnoOptions}
                      blurInputOnSelect={true}
                      menuPlacement="auto"
                      placeholder="Select Local Number"
                      className="w-100"
                      value={newUser.localNo ? { label: newUser.localNo, value: newUser.localNo } : null}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          localNo: e.value,
                        })
                      }
                    />
                  </div>
                )}
              </div>
              {newUser.isVaccinated && (
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">Vaccination Image:</label>
                    <ImageInput
                      cssClass="imagePathPublic"
                      setSelectedImage={setVaccinationImage}
                      selectedImage={vaccinationImage}
                    />
                  </div>
                  {newUser.isBooster && (
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Booster Image:</label>
                      <ImageInput
                        cssClass="imagePathPublic"
                        setSelectedImage={setBoosterImage}
                        selectedImage={boosterImage}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="first-last-name-wrapper mb-3">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders emp-gender-mb">Send Email: </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <RadioButton
                      name="sendEmail"
                      id="sendEmail-yes"
                      checked={newUser.sendEmail === 1}
                      label="Yes"
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          sendEmail: e.target.checked && 1,
                        })
                      }
                    />
                    <RadioButton
                      label="No"
                      name="sendEmail"
                      id="sendEmail-no"
                      checked={newUser.sendEmail === 2}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          sendEmail: e.target.checked && 2,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders emp-gender-mb">Send SMS: </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <RadioButton
                      label="Yes"
                      name="sendSMS"
                      id="sendSMS-yes"
                      checked={newUser.sendSMS === 1}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          sendSMS: e.target.checked && 1,
                        })
                      }
                    />
                    <RadioButton
                      label="No"
                      name="sendSMS"
                      id="sendSMS-no"
                      checked={newUser.sendSMS === 2}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          sendSMS: e.target.checked && 2,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {showPopOver && recordData.length > 1 && (
                <EmployeeSearchRecord
                  data={recordData}
                  handleSelect={populate}
                  handleClose={() => {
                    setShowPopOver(false), setSelectSearchData(null);
                  }}
                  setSelectSearchData={setSelectSearchData}
                  selectSearchdata={selectSearchdata}
                />
              )}
              {openConfirmation && (
                <ConfirmationModal
                  show={openConfirmation}
                  title="Update Call Time"
                  message={`${newUser.firstName} ${newUser.lastName} is currently in an active program. Update current program call time?`}
                  handleConfirm={handleConfirm}
                  handleClose={() => setOpenConfirmation(false)}
                  closeBtn={true}
                />
              )}
            </div>
            {showImageCroper && (
              <ImageCropModal selectedImage={copyImage} handleConfirm={handleCropDone}></ImageCropModal>
            )}
            {showProfileModal && (
              <ProfileRelationModal
                filterEmployees={appContext.employees.filter(
                  (f) => f.id !== user.id && connected.findIndex((i) => i.id === f.id) === -1
                )}
                employeesId={newUser.id}
                getConnectedProfiles={getConnectedProfiles}
                connected={connected}
                handleClose={() => {
                  setProfileModal(!showProfileModal);
                }}
              />
            )}
            {loading && <Loader />}
            <div id="section-to-print" className="printSide">
              {!qrPrint ? (
                <QrCodeCard profileImage={profileImage || randomImage} newUser={newUser} appContext={appContext} />
              ) : (
                <QrPrint newUser={newUser} appContext={appContext} />
              )}
            </div>
          </div>
          {!newUser.subID && (
            <div className="checkbox checkBoxWrapper justify-content-center appUserCreation align-items-center mb-3">
              <label className="modalLineHeaders">Create User for App:</label>
              <div className="d-flex justify-content-start align-items-center mt-3">
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    checked={newUser.isApp}
                    onChange={(e) => setNewUser({ ...newUser, isApp: e.target.checked })}
                  />
                </Form.Check>
              </div>
            </div>
          )}
          {!newUser.isNew && (
            <>
              <div
                className="relationContainer cursor-pointer"
                onClick={() => setExpandRelationList(!expandReltionList)}
              >
                <div className="label">Relations</div>
                <div className="plus cursor-pointer">
                  <i className="fa fa-edit" onClick={makeRelationModal} />
                  <i className={`fas ${expandReltionList ? "fa-angle-up" : "fa-angle-down"}`} />
                </div>
              </div>
              <div>
                {expandReltionList && (
                  <Table className="table">
                    <thead>
                      <tr>
                        <th className="tdWidth12">First Name</th>
                        <th className="tdWidth12">Last Name</th>
                        <th className="tdWidth12">Email</th>
                        <th className="tdWidth12">Relation</th>
                        <th className="tdWidth12">Manage As A</th>
                      </tr>
                    </thead>
                    <tbody>
                      {connected?.map((item, i) => {
                        return (
                          <tr>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.relation}</td>
                            <td>{item.manageAs}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {" "}
          {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(true, newUser)}
          >
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeModal;
