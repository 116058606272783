import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button, Overlay, Popover, Form } from "react-bootstrap";
import API from "api";
import { Link } from "react-router-dom";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, removeSpaceIns, getValidReg } from "utils";
import ProgramNameInput from "components/Modal/ProgramNameInput";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import Loader from "components/Loader/Loader";
import DepartmentImportModal from "components/Modal/DepartmentImportModal";
import { COLOR_VALUE, TEST_TYPE_VALUE } from "constant";
import HeaderItem from "components/Table/HeaderItem";
import ZoneModalCalendar from "components/Modal/ZoneModalCalender";
import moment from "moment";
import { formatCreatedDate } from "utils";
import MFPagination from "components/Pagination/MFPagination";
import ExportToExcel from "components/ExportToExcel";
import FilterPopOver from "components/FilterPopOver";
import { CONFIRMATION_TYPE, ZONES_PERSONALIZE } from "constant";
import { personalizationLocalStorage, tdCheckBox } from "utils";
import PersonalizationModal from "components/Modal/PersonalizationModal";

import Icon from "components/Icon";

const programSettings = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [programSettings, setProgramSettings] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [openImport, setOpenImport] = useState(false);
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  let program = appContext.isZoneLevelCompany() ? "Event" : "Program";
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const searchTerms = ["Name", "Color"];

  const selectedItem = [
    { itemKey: "createdAt" },
    { itemKey: "name" },
    { itemKey: "color" },
    appContext.isZoneLevelCompany() && { itemKey: "nextTestDate" },
    appContext.isZoneLevelCompany() && { itemKey: "nextQuestionDate" },
    appContext.isZoneLevelCompany() && { itemKey: "nextTestType" },
    { itemKey: "totalEmployees" },
    { itemKey: "createdByName" },
  ];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(
    width - 50,
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 8
  );

  const formatProgram = () => {
    const data = appContext.employees
      .filter((f) => f.programID)
      .reduce((obj, employee) => {
        obj[employee.programID] = (obj[employee.programID] || 0) + 1;
        return obj;
      }, {});

    setProgramSettings(
      nestedFilter(
        appContext.programs.map((t) => {
          const dates = t.testsData?.filter((f) => f.question).map((m) => m.date) || [];
          const quesGroup = appContext.question?.find((f) => f.id === t.scheduleID);
          const sortedDates = dates?.sort((a, b) => moment(a) - moment(b));
          const findDate = sortedDates?.find((d) => moment(d).isSameOrAfter(moment(), "day"));
          return {
            ...t,
            tests: "",
            totalEmployees: data[t.id] || 0,
            nextQuestionDate: findDate || "",
            nextQuestionScheduleName: quesGroup?.groupName || "",
          };
        }),
        filter
      )
    );
  };

  useEffect(() => {
    setPersonalize(personalizationLocalStorage.getPersonalize(appContext.user, "zone", ZONES_PERSONALIZE));
  }, [appContext?.company]);

  const headerAlign = (item) => (item === "center" ? "centered" : undefined);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "zoneColor" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  useEffect(() => {
    formatProgram();
    setLoading(false);
  }, [appContext.programs, appContext.employees, appContext.question, filter]);

  const sortPrograms = (zone, sortParam) => {
    if (sortDescending) {
      return [...zone].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...zone].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (programSettings.length > 0) setProgramSettings(sortPrograms(programSettings, sortBy));
  }, [sortBy, sortDescending]);

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    if (programSettings.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? programSettings.map((t) => t.id) : []);
  };

  const getDeleteMessage = () => {
    const departmentToDelete = programSettings.filter((d) => d.totalEmployees === 0 && checkboxes.indexOf(d.id) !== -1);
    const ids = departmentToDelete.map((d) => d.id);

    let departmentName = null;
    let totalDepartments = null;

    if (ids.length === 1) {
      const departmentToRemove = programSettings.find((d) => d.id === ids[0]);
      if (departmentToRemove) {
        departmentName = departmentToRemove.name;
      }
    } else {
      totalDepartments = ids.length;
    }
    return [ids, departmentName, totalDepartments];
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: `Delete ${program}`,
      message: `Are you sure you want to delete ${user.name}?`,
    });
    if (user) setItemToDelete(user);
  };

  const handleBulkDeleteClick = () => {
    let [ids, departmentName, totalDepartments] = getDeleteMessage();
    if (ids.length === 0) {
      appContext.showErrorMessage("Only unassigned zone can be deleted");
      return;
    }
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.MULTI_DELETE,
      title: `Delete ${program}`,
      message: departmentName
        ? `Are you sure you want to delete ${departmentName}?`
        : `Are you sure you want to delete the ${totalDepartments} zones selected?`,
    });
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        handleConfirmDelete();
        break;
      case CONFIRMATION_TYPE.MULTI_DELETE:
        deleteDepartment();
    }
  };

  const handleConfirmDelete = async () => {
    const filterData = appContext.employees.filter((emp) => emp.programID === itemToDelete.id);
    if (filterData.length > 0) {
      appContext.showErrorMessage(`This ${program} is Assign to Employees`);
      return;
    }
    setLoading(true);
    try {
      await API.deleteProgramSettings(itemToDelete.id);
      appContext.resetPrograms();
      appContext.showSuccessMessage(`${program} deleted successfully`);
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const deleteDepartment = async () => {
    try {
      let [ids, departmentName, totalDepartments] = getDeleteMessage();
      if (ids.length === 0) {
        appContext.showErrorMessage(`No ${program} to Delete`);
        return;
      }
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        await API.deleteProgramSettings(ids[i]);
      }
      if (departmentName) {
        appContext.showSuccessMessage(`${departmentName} ${program} has been deleted successfully`);
      } else {
        appContext.showSuccessMessage(`${totalDepartments} ${program} have been deleted successfully`);
      }
      appContext.resetPrograms();
    } catch (err) {
      console.log("Error", err);
      appContext.showErrorMessage(`Fail to Delete the ${program}`);
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const OnHandleProgramSettings = async (prog) => {
    setOpenCreateModal(false);
    setShowZoneModal(false);
    try {
      setLoading(true);
      console.log("Obj to Save: ", prog);
      if (prog.isNew) {
        (await API.newProgramSettings(prog, appContext.user))
          ? appContext.showSuccessMessage(`You have successfully created ${prog.name}`)
          : appContext.showErrorMessage(`${program} already exist`);
      } else {
        const isUpdated = await API.updateProgramSettings(prog, appContext.user);
        const filterProgramEmp = appContext.employees.filter((f) => f.programID === prog.id);
        let programData = filterProgramEmp.map((m) => {
          return {
            id: m.id,
            programID: m.programID,
            companyID: m.companyID,
            firstName: m.firstName,
            lastName: m.lastName,
            department: m.department,
          };
        });
        if (filterProgramEmp.length > 0 && isUpdated) {
          // setTimeout(async () => {
          //   await API.updateEmpTestSchedule(programData);
          // }, 2000);
          await API.updateEmpTestSchedule(programData);

          // await API.updateEmpProgram(filterProgramEmp, prog.name);
        }
        appContext.showSuccessMessage(`${program} Updated Successfully`);
      }
      appContext.resetPrograms();
      appContext.resetCompanyInfo();
      appContext.resetEmployees();
      setSelectItem(null);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };
  const handleEdit = (item) => {
    setSelectItem(item);
    if (appContext.isZoneLevelCompany()) {
      setShowZoneModal(true);
    } else {
      setOpenCreateModal(true);
    }
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleImport = async (importData) => {
    if (importData.length === 0) {
      setOpenImport(false);
      return;
    }

    if (Object.keys(importData[0].data)[0] !== "name") {
      appContext.showErrorMessage("First column Header must be name");
      setOpenImport(false);
      return;
    }
    if (Object.keys(importData[0].data)[1] !== "color") {
      appContext.showErrorMessage("Second column Header must be color");
      setOpenImport(false);
      return;
    }
    setLoading(true);
    const ttlLength = importData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = importData[index];
      if (Object.values(data).every((c) => c.length == 0)) continue;
      const obj = {
        name: getValidReg(data.name),
        color: data.color ? COLOR_VALUE[removeSpaceIns(data.color)] : "Black",
      };
      await API.newProgramSettings(obj);
    }
    setOpenImport(false);
    setLoading(false);
    appContext.resetPrograms();
    appContext.showSuccessMessage("Import Successfully");
  };

  const tdLink = (item) => (
    <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
      {item.totalEmployees > 0 ? (
        <Link
          style={{ color: "#42cef5" }}
          to={{
            pathname: "/admin/employees",
            state: { name: item.id, term: "programID" },
          }}
        >
          {item.totalEmployees}
        </Link>
      ) : (
        item.totalEmployees
      )}
    </td>
  );

  const tdFormat = (item, user) => {
    if (item === "createdAt") return formatCreatedDate(user.createdAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "color")
      return (
        <div
          className="programColor"
          title={`${user.color}`}
          style={{
            width: "10px",
            height: "10px",
            marginRight: "5px",
            backgroundColor: `${removeSpaceIns(user.color)}`,
          }}
        />
      );
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "totalEmployees") return tdLink(user);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={item.itemKey !== "color" && tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalizationLocalStorage.savePersonalize(data, "zone");
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const TableCollapse = ({ data, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{TEST_TYPE_VALUE[data.value]}</td>
        <td>{`${data.days} ${data.days > 1 ? "days" : "day"}`}</td>
      </tr>
    );
  };

  const TablePopOver = ({ data, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{TEST_TYPE_VALUE[data.value]}</td>
        <td>{`${data.days.length} ${data.days.length > 1 ? "days" : "day"}`}</td>
      </tr>
    );
  };

  const TableRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const childNode = useRef();
    let setTimeoutConst = null;
    const handleMouseEnter = () => {
      setTimeoutConst = setTimeout(() => {
        setShowPopover(true);
      }, 1000);
    };
    const handleMouseLeave = () => {
      clearTimeout(setTimeoutConst);
      setShowPopover(false);
    };
    let nextTestTypeLabel = "";
    const nextTestTypeArr = item.nextTestType ? item.nextTestType.split(",") : [];
    nextTestTypeLabel = nextTestTypeArr.length > 1 ? "Multi" : nextTestTypeArr[0];
    return (
      <>
        <tr key={item.id}>
          <td>
            {" "}
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                value={item.id}
                checked={checkboxes.indexOf(item.id) !== -1}
                onChange={(e) => handleCheckboxChange(e, item.id)}
              ></Form.Check.Input>
            </Form.Check>
          </td>
          <td className="icon">
            <img
              src={editIcon}
              alt="edit icon"
              width="18"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={() => handleEdit(item)}
            />
          </td>
          {personalize.map((data) => data.isCheck && renderTd(data, item))}

          {/* <td className="ellipsis ">{formatCreatedDate(item.createdAt)}</td>
          <td style={{ textAlign: "left", textOverflow: "visible" }}>{item.name}</td>
          <td style={{ textAlign: "center", textOverflow: "visible" }}>
            <div
              className="programColor"
              style={{
                width: "10px",
                height: "10px",
                marginRight: "5px",
                backgroundColor: `${removeSpaceIns(item.color)}`,
              }}
            />
          </td>
          {appContext.isZoneLevelCompany() && (
            <>
              <td style={{ textAlign: "center" }} title={item.nextTestType}>
                {item.nextTestDate}
              </td>
              <td style={{ textAlign: "center" }} title={item.nextTestType}>
                {nextTestTypeLabel}
              </td>
              <td style={{ textAlign: "center" }} title={item.nextQuestionScheduleName}>
                {item.nextQuestionDate}
              </td>
            </>
          )}
          <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
            {item.totalEmployees > 0 ? (
              <Link
                style={{ color: "#42cef5" }}
                to={{
                  pathname: "/admin/employees",
                  state: { name: item.id, term: "programID" },
                }}
              >
                {item.totalEmployees}
              </Link>
            ) : (
              item.totalEmployees
            )}
          </td>
          <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
            {item.createdByName || ""}
          </td> */}
          <td style={{ textAlign: "center", textOverflow: "visible" }}>
            <img
              src={trashIcon}
              alt="trash icon"
              width="15"
              style={{ marginLeft: "10px" }}
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={(e) => openDeleteConfirmation(item)}
            />
          </td>
        </tr>
      </>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Total {program}s ({programSettings?.length})
                </Card.Title>
                <div className="buttonHeader">
                  {/* <Button
                    className="headerButton btn-fill"
                    variant="primary"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    Filter
                  </Button> */}
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (programSettings && programSettings.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <Icon
                    handleClick={() => handleBulkDeleteClick()}
                    label="Delete"
                    title="Delete"
                    disabled={checkboxes.length === 0}
                    iconType={"binIcon"}
                  />
                  <Icon
                    handleClick={() =>
                      appContext.isZoneLevelCompany() ? setShowZoneModal(true) : setOpenCreateModal(true)
                    }
                    title={`Create ${program}`}
                    label="Create"
                    iconType="createIcon"
                  />

                  {programSettings.length > 0 && (
                    <ExportToExcel
                      items={programSettings}
                      selectedItem={selectedItem}
                      appContext={appContext}
                      filter={filter}
                      title="Zone Data"
                      sortBy={sortBy}
                    />
                  )}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={"Edit Columns"}
                    label={"Edit Columns"}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenImport(true)}
                    title={`Import ${program}`}
                    label="Import"
                    iconType="importIcon"
                  />
                </div>

                {showFilter && (
                  <FilterPopOver
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  totalSelected={checkboxes.length}
                  showAll
                  showSelectedRecord
                  selectedLabel="Selected Zones"
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    programSettings.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th></th>
                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.textAlign)}
                              />
                            )
                        )}
                        {/* <HeaderItem
                          width={tdWidth}
                          ItemKey="createdAt"
                          title="Date Created"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="color"
                          title="Zone Color"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        {appContext.isZoneLevelCompany() && (
                          <>
                            <HeaderItem
                              width={tdWidth}
                              ItemKey="nextTestDate"
                              title="Next Test Date"
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              aligned="centered"
                            />
                            <HeaderItem
                              width={tdWidth}
                              ItemKey="nextTestType"
                              title="Test Type"
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              aligned="centered"
                            />
                            <HeaderItem
                              width={tdWidth}
                              ItemKey="nextQuestionDate"
                              title="Next Pre-Screen Date"
                              flipSort={flipSort}
                              sortBy={sortBy}
                              sortDescending={sortDescending}
                              aligned="centered"
                            />
                          </>
                        )}
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalEmployees"
                          title="No of Crew"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="createdByName"
                          title="Created By"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        /> */}
                        <th className={`border-0 centered`} title="Action">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {programSettings.map((item, i) => {
                        return <TableRow key={i} item={item} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {openConfirmation.isShow && (
            <ConfirmationModal
              show={openConfirmation.isShow}
              actionType={openConfirmation.actionType}
              title={openConfirmation.title}
              message={openConfirmation.message}
              handleConfirm={onHandleConfirmation}
              closeBtn
            />
          )}

          {openCreateModal && (
            <ProgramNameInput
              programSetting={true}
              handleClose={() => {
                setSelectItem(null), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleProgramSettings}
              programs={appContext.programs}
              program={program}
            />
          )}
          {openPersonalizationModal && (
            <PersonalizationModal
              data={JSON.stringify(personalize)}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}
          {openImport && <DepartmentImportModal handleClose={() => setOpenImport(false)} handleImport={handleImport} />}
          {showZoneModal && (
            <ZoneModalCalendar
              programSetting={true}
              handleClose={() => {
                setSelectItem(null), setShowZoneModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleProgramSettings}
              programs={appContext.programs}
              questionSchedule={appContext.schedules.filter((f) => f.scheduleType === "calendar")}
              program={program}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default programSettings;
