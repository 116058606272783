import React from "react";
import { formatNumber } from "utils";
import { removeSpaceIns } from "utils";
import { Form } from "react-bootstrap";
import RadioButton from "components/RadioButton/RadioButton";

const ScheduleZoneEventBar = (props) => {
  const { childNode, event, testByZone, handleClickCustomSch, isEventChecked, setIsEventChecked } = props;

  return event.testTypes.map((m) => (
    <div className="event-bar mb-2" key={event.id}>
      <span
        className="event-bar-left"
        style={{
          backgroundColor: `${removeSpaceIns(event?.color || "#000")}`,
        }}
      />
      <div style={{ marginTop: "1px", position: "absolute", left: "22px", zIndex: "9999" }}>
        <RadioButton
          name="event"
          id={m}
          handleChange={(e) => setIsEventChecked(e.target.checked ? m : null)}
          checked={isEventChecked === m}
        />
      </div>
      <span
        style={{
          backgroundColor: `${removeSpaceIns(event?.color || "#000")}`,
        }}
        onClick={() => handleClickCustomSch && handleClickCustomSch(event, m)}
        title={
          testByZone
            ? `${event.testTypes.join()}`
            : event.testTypes.length > 1
            ? `${event.name}: ${event.testTypes.join()}`
            : `${event.name}`
        }
        className="event d-flex align-items-center justify-content-between pe-3"
        ref={childNode}
      >
        <span className="eventName custom-ellipsis d-block">{m}</span>
        <span className="eventName">
          {testByZone
            ? ` (${formatNumber(event.employees.length)})`
            : event.testTypes.length > 1
            ? ` (${formatNumber(event.employees.length)})`
            : ` (${formatNumber(event.employees.length)})`}
        </span>
      </span>
    </div>
  ));
};

export default ScheduleZoneEventBar;
