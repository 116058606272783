import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "context/app-context";
import { getDaysArray } from "utils";
import GlobalSearch from "components/GlobalSearch";
import FilterPopOver from "components/FilterPopOver";
import { personalizationLocalStorage } from "utils";
import api from "api";
import ViewportList from "react-viewport-list";
import Loader from "components/Loader/Loader";
import EosEventBar from "./Components/EosEventBar";
import GroupLabelTab from "components/GroupLabelTab";
import { EOS_MODAL_GROUPS } from "constant";
import EmployeeModal from "components/Employee/EmployeeModal";
import { formatCallTime } from "utils";
import { Form } from "react-bootstrap";
import Icon from "components/Icon";
import { t } from "constants/stringConstants";
import EOSItemsModal from "components/Modal/EOSItemsModal";
import CalendarSideBarHeader from "./Components/CalendarSideBarHeader";
import { formatEmployeesForCustomSch } from "utils";
import { CONFIG } from "utils";
import { formatNumber } from "utils";

const ExpectedOnSetCalendarDetails = (props) => {
  const {
    selectedData,
    setSelectedData,
    departments,
    setCustomScheduleData,
    customScheduleData,
    eosModal,
    selectedGroup,
  } = props;

  const [employees, setEmployees] = useState([]);
  const [filteredEmps, setFilteredEmps] = useState({ eosEmps: [], nonEosEmps: [] });
  const [totalEOSEmps, setTotalEOSEmps] = useState([]);
  const [totalNonEOSEmps, setTotalNonEOSEmps] = useState([]);
  const [empsToShow, setEmpsToShow] = useState([]);
  const [expectedOnSet, setExpectedOnSet] = useState(true);
  const [filter, setFilter] = useState({});
  const [lsData, setLsData] = useState(personalizationLocalStorage.getStorage() || {});
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [openCreator, setOpenCreator] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showItemsModalData, setShowItemsModalData] = useState(null);

  const appContext = useContext(AppContext);

  let program = appContext.isZoneLevelCompany() ? "Event" : "Program";
  const searchTerms = [`Office`, `${program} Name`, "Department"];

  useEffect(() => {
    if (selectedData.date && departments) {
      getExpectedOnSetEmps();
    }
  }, [selectedData.date, departments, appContext.employees]);

  useEffect(() => {
    const eosStorage = personalizationLocalStorage.getStorage().eosSelectionData;

    setExpectedOnSet(eosStorage.eosSelcted === "1");

    const filData = {};
    if (eosStorage.zonesSelected.length > 0) {
      const zonesArr = [];
      eosStorage.zonesSelected.forEach((id) => {
        zonesArr.push(appContext.programs.find((p) => p.id === id).name);
      });

      Object.assign(filData, {
        programName: zonesArr,
      });
    }
    setFilter(filData);
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setExpectedOnSet(selectedGroup.value === "eos");
      setCheckboxes([]);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (!customScheduleData) {
      setCheckboxes([]);
      setSelectAll(false);
    }
  }, [customScheduleData]);

  const getExpectedOnSetEmps = () => {
    const emps = appContext.employees;
    const tempNonEOSEmps = [];
    const tempEOSEmps = [];
    emps.forEach((emp) => {
      let isExist = false;
      if (emp.onSetDates) {
        if (emp.onSetDates?.dates?.length > 0) {
          if (emp.onSetDates.dates.findIndex((d) => d === selectedData.date) !== -1) isExist = true;
        } else if (emp.onSetDates?.startDate && emp.onSetDates?.endDate) {
          const datesArr = moment(selectedData.date).isBetween(emp.onSetDates?.startDate, emp.onSetDates?.endDate);
          if (datesArr) isExist = true;
        }
      }
      const empDept = departments.find((f) => f.id === emp.department);
      const empData = {
        ...emp,
        fullName: `${emp.firstName} ${emp.lastName}`,
        departmentName: empDept?.name || "",
      };
      if (isExist) {
        tempEOSEmps.push(empData);
      } else {
        tempNonEOSEmps.push(empData);
      }
    });
    setTotalEOSEmps(tempEOSEmps);
    setTotalNonEOSEmps(tempNonEOSEmps);
    handleEmpsToShow(emps);
  };

  const filterTotalEmps = (eosEmps = totalEOSEmps, nonEosEmps = totalNonEOSEmps) => {
    const filterRes = {
      eosEmps: filterEmps(eosEmps, filter),
      nonEosEmps: filterEmps(nonEosEmps, filter),
    };
    setFilteredEmps(filterRes);
    setEmpsToShow(expectedOnSet ? filterRes.eosEmps : filterRes.nonEosEmps);
    setSelectAll(false);
  };

  const handleEmpsToShow = (emps) => {
    setEmployees(emps || (expectedOnSet ? totalEOSEmps : totalNonEOSEmps));
    filterTotalEmps();
  };

  const handleSaveGolobalSearch = (search) => {
    if (search !== filter.fullName) setFilter({ ...filter, fullName: search });
  };

  useEffect(() => {
    if (employees) {
      filterTotalEmps();
    }
  }, [filter]);

  useEffect(() => {
    handleEmpsToShow();
  }, [expectedOnSet, totalEOSEmps]);

  const filterEmps = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }

        if (key === "programName" && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]?.toLowerCase());
      });
    });
    return models;
  };

  useEffect(() => {
    handleSaveSelection();
  }, [filter.programName, expectedOnSet]);

  const handleSaveSelection = async (checked = expectedOnSet) => {
    let zonesSelected =
      filter.programName?.length > 0
        ? appContext.programs.filter((p) => filter.programName.includes(p.name)).map((p) => p.id)
        : [];
    const objToSave = {
      ...lsData,
      eosSelectionData: {
        eosSelcted: checked ? "1" : "0",
        zonesSelected,
      },
    };
    personalizationLocalStorage.save(JSON.stringify(objToSave));

    try {
      await api.udpatePersonalization(appContext.user.phone_number, objToSave);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateExpectedOnSet = async (emp) => {
    setLoading(true);
    let eosDates = [];
    eosDates = [
      ...(emp.onSetDates?.dates ||
        (emp.onSetDates?.start ? getDaysArray(emp.onSetDates.start, emp.onSetDates.end) : [])),
    ];
    const findDate = eosDates.indexOf(selectedData.date);
    let eosToday = false;
    if (findDate === -1) {
      eosDates.push(selectedData.date);
      if (selectedData.date === moment().format("YYYY-MM-DD")) eosToday = true;
    } else {
      eosDates.splice(findDate, 1);
    }
    const datesToSave = { dates: eosDates };
    try {
      await api.updateExceptedOnSet([emp.id], appContext.user.name, eosToday, datesToSave);
      setLoading(false);
      appContext.resetEmployees();
    } catch (error) {
      setLoading(false);
      appContext.showErrorMessage(error.message);
      console.log(error);
    }
  };

  const handleEditUser = (emp) => {
    const obj = { ...emp, isNew: false, sendEmail: emp.sendEmail || 1, sendSMS: emp.sendSMS || 1 };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const createUser = (user, result) => {
    appContext.showSuccessMessage("Employee updated successfully");
    appContext.resetEmployees();
  };

  const handleChangeSelection = (e, emp) => {
    const index = checkboxes.indexOf(emp.id);
    if (index === -1) {
      setCheckboxes([...checkboxes, emp.id]);
    } else {
      const prevList = checkboxes;
      prevList.splice(index, 1);
      setCheckboxes([...prevList]);
    }
  };

  const toggleCheckboxes = (value) => {
    const employeeIdsInView = empsToShow.map((e) => e.id);
    if (value) {
      const newIds =
        checkboxes.length > 0 ? employeeIdsInView.filter((e) => checkboxes.indexOf(e) === -1) : employeeIdsInView;
      setCheckboxes(checkboxes.concat(newIds));
    } else {
      const newIds =
        checkboxes.length > 0 ? checkboxes.filter((e) => employeeIdsInView.indexOf(e) === -1) : employeeIdsInView;
      setCheckboxes(newIds);
    }
  };

  const handleShowSelectedItem = () => {
    if (checkboxes.length === 0) return;
  };

  const handleUpdateEmployee = async () => {
    setLoading(true);
    try {
      for (let i = 0; i < checkboxes.length; i++) {
        const Id = checkboxes[i];
        const emp = empsToShow.find((f) => f.id === Id);
        if (emp) {
          let eosDates = [];
          eosDates = [
            ...(emp.onSetDates?.dates ||
              (emp.onSetDates?.start ? getDaysArray(emp.onSetDates.start, emp.onSetDates.end) : [])),
          ];
          const findDate = eosDates.indexOf(selectedData.date);
          let eosToday = false;
          if (findDate === -1) {
            eosDates.push(selectedData.date);
            if (selectedData.date === moment().format("YYYY-MM-DD")) eosToday = true;
          } else {
            eosDates.splice(findDate, 1);
          }
          const datesToSave = { dates: eosDates };
          await api.updateExceptedOnSet([emp.id], appContext.user.name, eosToday, datesToSave);
        }
      }
      setLoading(false);
      appContext.showSuccessMessage(
        `Successfully ${formatNumber(checkboxes.length)} Crew ${checkboxes.length === 1 ? "member" : "members"}  ${
          expectedOnSet ? " removed" : " added"
        } ${CONFIG.expectedOnSet} List.`
      );
      appContext.resetEmployees();
      setCheckboxes([]);
    } catch (error) {
      setLoading(false);
      appContext.showErrorMessage(error.message);
      console.log(error);
    }
  };

  const handleMoreAction = (emp) => {
    setShowItemsModalData(
      formatEmployeesForCustomSch(JSON.stringify({ employees: [emp] }), selectedData.date, appContext.programs)
    );
  };

  const handleSelectEvent = (event, emp) => {
    console.log("event", event);
    setShowItemsModalData(null);
    switch (event.value) {
      case "addEOS":
      case "removeEOS":
        handleUpdateExpectedOnSet(emp);
        break;
    }
  };

  const handleCustomSch = () => {
    const selectEmps = empsToShow.filter((f) => checkboxes.includes(f.id));
    setCustomScheduleData({ date: selectedData.date, employees: selectEmps, expectedOnSet: true });
  };

  return (
    <>
      <div
        className={eosModal ? "calendar-details eos-calendar-details" : "h-100 d-flex flex-column"}
        style={{ border: "1px solid #ddd", borderRight: "0" }}
      >
        {eosModal && (
          <>
            <CalendarSideBarHeader
              date={selectedData.date}
              defaultCallTime={appContext.company?.defaultCallTime}
              setSelectedData={setSelectedData}
            />

            <GroupLabelTab
              selectedGroup={EOS_MODAL_GROUPS.find((group) => group.value === (expectedOnSet ? "eos" : "neos"))}
              setSelectedGroup={(group) => {
                setCheckboxes([]);
                setExpectedOnSet(group.value === "eos");
              }}
              groups={EOS_MODAL_GROUPS}
              cssClass="eosDetails justify-content-around py-2"
              eosModal
              labelConcat={filteredEmps}
            />
          </>
        )}

        <GlobalSearch
          eosModal
          cssClass={`eos-searchBar  mt-1 ${showFilter && "showFilterActive"}`}
          handleSave={handleSaveGolobalSearch}
          globalSearchText={filter.fullName || ""}
          handleFilterClick={() => setShowFilter(!showFilter)}
        />

        {showFilter && (
          <FilterPopOver
            eosModal
            cssClass={"eosFilterWrapper"}
            filterTerms={searchTerms}
            setFilter={setFilter}
            filter={filter}
            program={program}
          />
        )}

        <hr />
        <div className="d-flex align-items-center justify-content-between mb-2" style={{ paddingInline: "26px" }}>
          <div
            className="d-flex align-items-center"
            onClick={() => {
              if (empsToShow && empsToShow.length > 0) {
                setSelectAll(!selectAll);
                toggleCheckboxes(!selectAll);
              }
            }}
          >
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={selectAll}
                value="selectAll"
                style={{ marginTop: eosModal ? "7px" : "" }}
              />
            </Form.Check>
            <span className="ms-1 d-inline-block selectedTextSpan">{!selectAll ? "Select All" : "Deselect All"}</span>
          </div>

          <span className="event selectedTextSpan" onClick={handleShowSelectedItem}>
            Selected: {formatNumber(checkboxes.length)}
          </span>
          <div>
            <i
              onClick={() => checkboxes.length > 0 && handleUpdateEmployee()}
              style={{ fontSize: "20px", color: checkboxes.length === 0 ? "grey" : "#bf1e2e" }}
              className={`fa ${expectedOnSet ? "fa-minus-circle" : "fa-plus-circle"} mx-2`}
              title={expectedOnSet ? "Remove" : "Add"}
            />
          </div>
        </div>
        <div className="details-container px-3">
          <div className={`events-container ${showFilter && "filter-container"}`}>
            {empsToShow.length > 0 && (
              <ViewportList items={empsToShow} itemMinSize={42} margin={16} overflowAnchor="auto" withCache={false}>
                {(emp) => (
                  <EosEventBar
                    emp={emp}
                    key={emp.id}
                    handleUpdateExpectedOnSet={handleUpdateExpectedOnSet}
                    handleEditUser={handleEditUser}
                    expectedOnSet={expectedOnSet}
                    isSelected={checkboxes.indexOf(emp.id) !== -1}
                    date={selectedData.date}
                    onChangeSelection={handleChangeSelection}
                    handleMoreAction={handleMoreAction}
                  />
                )}
              </ViewportList>
            )}
          </div>
        </div>
        {loading && <Loader />}

        {showItemsModalData && (
          <EOSItemsModal
            emp={{ ...showItemsModalData[0] }}
            handleClose={() => setShowItemsModalData(null)}
            expectedOnSet={expectedOnSet}
            appContext={appContext}
            date={selectedData.date}
            handleSelectEvent={handleSelectEvent}
          />
        )}

        {openCreator && (
          <EmployeeModal
            cssClass="eos-employeeModal"
            user={newUser}
            handleClose={() => {
              setOpenCreator(false);
            }}
            handleSave={createUser}
            eosModal
            program={program}
          />
        )}
      </div>
    </>
  );
};

export default ExpectedOnSetCalendarDetails;
