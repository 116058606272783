import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button, Form, Container } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, differenceBtwDays, formatEmployeesData, saveMatrixDatesInLC } from "utils";
import { DAYS, FUTURE_INITAIL_DATE, NOTIFICATION_FROM, TEST_TYPE_VALUE } from "constant";
import moment from "moment";
import { removeSpaceIns, formatDateMatrix, getDaysArray, isTooDark } from "utils";
import Loader from "components/Loader/Loader";
import MatrixView from "components/MatrixView";
import MatrixPopOver from "components/Matrix/MatrixPopOver";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { formatDateMDY, sortList } from "utils";
import FilterPopOver from "components/FilterPopOver";
import NotesModal from "components/Modal/NotesModal";
import ZoneTestNowModal from "components/Modal/ZoneTestNowModal";
import NotificationInputModal from "components/Modal/NotificationInputModal";
import Status from "components/Status/Status";
import ClearedProgramModal from "components/Modal/ClearedProgramModal";
import GlobalSearch from "components/GlobalSearch";
import Icon from "components/Icon";
import { TCMatrixColsWidth } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { personalizationLocalStorage } from "utils";
import ExternalTestProgramModal from "components/Modal/ExternalTestProgramModal";
import TestDetailsMatrixModal from "components/Modal/TestDetailsMatrixModal";
import { t } from "constants/stringConstants";
import CustomScheduleModal from "components/Modal/CustomSchedule/CustomScheduleModal";
import EmployeeModal from "components/Employee/EmployeeModal";
import Stats from "components/Stats";
import MainTable from "components/ZoneMatrix/MainTable";
import debounce from "debounce";
import { differenceBtw2Days } from "utils";
import ShowAlertMessage from "components/ShowAlertMessage";
import { CONFIG } from "utils";
import MFPagination from "components/Pagination/MFPagination";
import { formatNumber } from "utils";

const ZoneMatrix = () => {
  const mainWidth = useRef(0);
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [programData, setProgramData] = useState({});
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [dateList, setDateList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showLeveMtarix, setShowLevelMatrix] = useState(saveMatrixDatesInLC.get().type === "D");
  const [date, setDate] = useState(saveMatrixDatesInLC.get());
  const [logDateRange, setLogDateRange] = useState(saveMatrixDatesInLC.get());
  const [showClearedProgram, setShowClearedProgram] = useState(false);
  const [clearWorkEmp, setClearWorkEmp] = useState([]);
  const [triggerFilter, setTriggerFilter] = useState();
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [matrixData, setMatrixData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [zoneTestNow, setZoneTestNow] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState([]);
  const [addSpecificNotesEmp, setAddSpecificNotesEmp] = useState({});
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [openCreator, setOpenCreator] = useState(false);
  const [employee, setEmployee] = useState({});
  const [showPopover, setShowPopover] = useState(false);
  const [viewChanging, setViewChanging] = useState(false);
  const [customSchData, setCustomSchData] = useState(null);
  const [updatedEmp, setUpdatedEmp] = useState(null);
  const [showStats, setShowStats] = useState(false);
  const [statsData, setStatsData] = useState({
    checkInRequire: 0,
    checkInRemaining: 0,
    testRequire: 0,
    testRemaining: 0,
    psqRequire: 0,
    psqRemaining: 0,
    Antigen: 0,
    PCR: 0,
    Molecular: 0,
  });
  const [deptLeftStickPosition, setDeptLeftStickPosition] = useState(
    personalizationLocalStorage.getStorage()?.tcMatrix?.deptLeftStickPosition || []
  );
  const [zoneLeftStickPosition, setZoneLeftStickPosition] = useState(
    personalizationLocalStorage.getStorage()?.tcMatrix?.zoneLeftStickPosition || []
  );
  const [showExternalTestNotification, setShowExternalTestNotification] = useState(false);
  const [showExternalTestData, setShowExternalTestData] = useState({ visible: false, emp: null, date: null });
  const [showExternalTest, setShowExternalTest] = useState(false);

  const [secondTableColsWidth, setSecondTableColsWidth] = useState(0);
  const [testDetailsMatrixData, setTestDetailsMatrixData] = useState({
    visible: false,
    emp: null,
    date: null,
    dayOpt: null,
    testType: [],
  });
  let defaultProgram = appContext.isZoneLevelCompany() ? "Event" : "Program";
  const dateInputRef = useRef(null);
  const listRef = useRef(null);
  // const tableWrapperRef = useRef(null);

  // const handleSecondTableWidth = () => {
  //   if (tableWrapperRef.current) {
  //     const totalWidth = tableWrapperRef.current.offsetWidth;
  //     const occupiedWidth = showLeveMtarix ? 300 : 280;
  //     const changedWidth = Math.ceil((totalWidth - occupiedWidth) / differenceBtw2Days(date.endDate, date.startDate));

  //     setSecondTableColsWidth(changedWidth >= 120 ? changedWidth : 120);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleSecondTableWidth);

  //   return () => {
  //     window.removeEventListener("resize", handleSecondTableWidth);
  //   };
  // });

  // useEffect(() => {
  //   handleSecondTableWidth();
  // }, [showLeveMtarix, date]);

  console.log("filteredPrograms", filteredPrograms);

  useEffect(() => {
    if (showExternalTestData.emp?.id) setShowExternalTest(true);
  }, [showExternalTestData]);

  const isExpectedLevel = () => (appContext.company?.onSet === 1 ? true : false);

  useEffect(() => {
    if (testDetailsMatrixData.visible && testDetailsMatrixData.emp.id) setShowPopover(true);
  }, [testDetailsMatrixData]);

  const updatePersonalization = async (personalisationData) => {
    try {
      await API.udpatePersonalization(appContext.user.phone_number, personalisationData);
    } catch (error) {
      console.log(error);
    }
  };

  const scrollSaveDelay = useCallback(
    debounce((data) => {
      saveScrollToStorage(data);
    }, 500),
    []
  );

  const saveScrollToStorage = (indexRange) => {
    const objToSave = personalizationLocalStorage.getStorage();
    Object.assign(objToSave, {
      tcMatrix: {
        ...personalizationLocalStorage.getStorage().tcMatrix,
        visibleStartIndex: indexRange.startIndex || 0,
      },
    });
    personalizationLocalStorage.save(JSON.stringify(objToSave));
  };

  const handleUserProfile = async (item) => {
    setEmployee(item);
    setOpenCreator(true);
  };

  const updateUserProfile = async (item) => {
    setUpdatedEmp(item.id);
    appContext.showSuccessMessage("Employee updated successfully");
    appContext.resetEmployees();
  };

  const handleRemoveExternalTest = async (test) => {
    let checkInID = "";
    let checkInRemove = "";
    const doneTestTwo = testDetailsMatrixData.emp.testTwo.filter((f) => f.isDone);
    if (doneTestTwo.length === 1) {
      const checkInData = testDetailsMatrixData.emp.checkInData.find(
        (f) => moment(f.createdAt).format("YYYY-MM-DD") === testDetailsMatrixData.date
      );
      if (checkInData) {
        checkInID = checkInData.id;
        checkInRemove = "remove";
      }
    }
    try {
      await Promise.all([
        API.removeExternalTest(testDetailsMatrixData.emp.id, test, checkInRemove),
        API.addLogsRemoveExternalTest(test, testDetailsMatrixData.emp, appContext.user, checkInID),
      ]);
      appContext.showSuccessMessage(`${TEST_TYPE_VALUE[test.test_type || test.value]} successfully deleted`);
      appContext.resetMatrixTestDone();
    } catch (err) {
      console.log("Error:-", err.message);
    }
    setLoading(false);
  };

  const handleCloseRemoveTest = (value) => {
    setTestDetailsMatrixData({
      visible: false,
      emp: null,
      date: null,
      dayOpt: null,
      testType: [],
    });
    setShowPopover(false);
    if (value) {
      appContext.resetEmployees();
    }
  };

  const handleExternaltest = async (userObject, logObject) => {
    setShowExternalTestData({
      visible: false,
      emp: null,
      date: null,
    });
    setShowExternalTest(false);
    if (!userObject) return;
    try {
      setLoading(true);
      await API.uploadExternalTest(userObject, true);
      if (logObject) {
        await API.addlogs([logObject]);
      }
      await appContext.resetMatrixTestDone();
      appContext.showSuccessMessage("Successfully added external test");
      setLoading(false);
      appContext.resetEmployees();
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    formattedEmployee();
  }, [appContext.employees, appContext.programs, appContext.schedules, appContext.company, departments]);

  const tdWidth = calculateTdWidth(width - 180, 19);

  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
  };

  const sortData = (sortParam, sortDescending, emps) => {
    if (sortDescending) {
      return [...emps].sort((a, b) => {
        const testerB = b[sortParam] ?? "";
        const testerA = a[sortParam] ?? "";
        return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
      });
    } else {
      return [...emps].sort((a, b) => {
        const testerB = b[sortParam] ?? "";
        const testerA = a[sortParam] ?? "";
        return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    setDateList(getDaysArray(date.startDate, date.endDate));
    getDepartments();
  }, []);

  useEffect(() => {
    appContext.resetMatrixTestDone();
  }, []);

  useEffect(() => {
    setMatrixData(appContext.matrixData);
  }, [appContext.matrixData]);

  useEffect(() => {
    if (Object.keys(date).length > 0) {
      if (differenceBtwDays(date.startDate, date.endDate) < 14) {
        handleSaveAppliedDate();
        setLogDateRange(date);
        setDateList(getDaysArray(date.startDate, date.endDate));
      } else {
        appContext.showErrorMessage("Date Range should be 2 weeks");
        dateInputRef.current.setStartDate(new Date(logDateRange.startDate));
        dateInputRef.current.setEndDate(new Date(logDateRange.endDate));
        setDate(logDateRange);
      }
    }
  }, [date]);

  const handleSaveAppliedDate = async () => {
    try {
      saveMatrixDatesInLC.save(date);
      await appContext.resetMatrixTestDone({
        startDate: moment(date.startDate).toISOString(),
        endDate: moment(date.endDate).toISOString(),
      });
    } catch (e) {
      console.log(e);
    }
  };

  let program = appContext.isZoneLevelCompany() ? "Event" : "Program";
  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "isVaccinated",
    "Zone Name",
    "Office",
    "Region",
    `${program} Name`,
    "Zone Color",
    "Department",
    "Check In",
    "Result",
    "PSQ",
    "Quarantine",
    `${program} Status`,
    CONFIG.onSetDateRange,
    "Test",
    CONFIG.expectedOnSet,
    "Drive on Access",
    "Positive Employee",
    "Todays Test",
    "Todays Question Schedule",
    // "Location",
    "Test Location",
  ];

  useEffect(() => {
    if (checkboxes.length === 0) {
      setSelectAll(false);
    }
  }, [checkboxes]);

  const formattedEmployee = async () => {
    const departments = await API.getDepartments();
    const emps = formatEmployeesData(
      appContext.employees,
      appContext.schedules,
      departments,
      appContext.programs,
      appContext.company
    );
    setEmployees(emps);
  };

  const handleSaveTestNow = (obj) => {
    if (obj) {
      appContext.showSuccessMessage("Add Test Now Successfully");
      appContext.resetEmployees();
      setCheckboxes([]);
      setSelectAll(false);
    }
  };

  const AddNotes = (note, date, emp) => {
    setOpenNotesModal(false);
    const val = note ? note : null;
    handleRemoveNote({ ...emp, ...addSpecificNotesEmp, notes: val });
    setAddSpecificNotesEmp({});
  };

  const getEmployeeProgramDetails = (emp, empProgram) => {
    const clone = { ...emp };
    let dataList = [];
    let checkInData = [];
    let qaData = [];
    const DateList = getDaysArray(date.startDate, date.endDate);

    if (clone.programID || (clone.CustomSchedule && clone.CustomSchedule.length > 0)) {
      if (empProgram && empProgram.schedule) {
        let empTestDoneList = [];
        const scheduleClone = [...empProgram.schedule];

        if (matrixData && matrixData[emp.id]) {
          empTestDoneList = matrixData[emp.id];
          if (empTestDoneList.length === 0) {
            dataList = scheduleClone;
          } else {
            dataList = empTestDoneList.reduce((arr, e) => {
              let index = -1;
              if (e.auditType === "CovidTest" || e.auditType === "ExternalTest") {
                index = arr.findIndex((t) => {
                  if (t.sequenceNo && e.sequenceNo) {
                    return t.sequenceNo === e.sequenceNo;
                  }
                  return moment(e.createdAt).format("YYYY-MM-DD") === t.date && t.value === e.test_type;
                });

                if (index === -1) {
                  arr.push({
                    ...e,
                    isDone: true,
                    label: TEST_TYPE_VALUE[e.test_type || e.value],
                    date: moment(e.createdAt).format("YYYY-MM-DD"),
                  });
                }
              }

              if (index !== -1) {
                const newObj = { ...arr[index], isDone: true, ...e, id: e.id };
                if (e.result) {
                  Object.assign(newObj, { result: e.result, id: e.id });
                }
                arr.splice(index, 1, newObj);
              }
              return arr;
            }, scheduleClone);
          }
        } else {
          dataList = scheduleClone;
        }
      }
    }
    if (emp.onBoardingTesting && emp.onBoardingTesting.length > 0) {
      emp.onBoardingTesting.forEach((test) => {
        if (matrixData && matrixData[emp.id]) {
          const isTestNow = matrixData[emp.id].find(
            (f) =>
              moment(f.createdAt).format("YYYY-MM-DD") === test.testNowDate && f.test_type === test.value && f.testNow
          );
          if (!isTestNow && !test.isDone && DateList.includes(test.testNowDate)) {
            dataList.push({
              ...test,
              date: test.testNowDate,
            });
          } else if (isTestNow && dataList.findIndex((s) => s.id === isTestNow.id) === -1) {
            dataList.push({
              ...isTestNow,
              isDone: true,
              label: TEST_TYPE_VALUE[isTestNow.test_type || isTestNow.value],
              date: moment(isTestNow.createdAt).format("YYYY-MM-DD"),
            });
          }
        } else if (!test.isDone && DateList.includes(test.testNowDate)) {
          dataList.push({
            ...test,
            date: test.testNowDate,
          });
        }
      });
    }
    if (matrixData && matrixData[emp.id]) {
      const empLogChcekList = matrixData[emp.id].filter((f) => f.auditType === "CheckIn");
      const empQaDone = matrixData[emp.id].filter(
        (f) => f.auditType === "PreScreeningQuestion" || f.auditType === "ClearedForWork"
      );
      const isTest = matrixData[emp.id].filter(
        (f) =>
          dataList.findIndex((s) => s.id === f.id) === -1 &&
          (f.auditType === "CovidTest" || f.auditType === "ExternalTest")
      );

      if (isTest.length > 0) {
        isTest.forEach((f) => {
          dataList.push({
            ...f,
            isDone: true,
            label: TEST_TYPE_VALUE[f.test_type || f.value],
            date: moment(f.createdAt).format("YYYY-MM-DD"),
          });
        });
      }
      if (empLogChcekList.length > 0) {
        checkInData.push(...empLogChcekList);
      }
      if (empQaDone.length > 0) {
        qaData.push(...empQaDone);
      }
    }

    Object.assign(clone, {
      scheduleData: dataList,
      checkInData,
      qaData,
    });
    return clone;
  };

  const getProgramBySchedule = (programID, datesList) => {
    let newList = [];
    const empProgram = appContext.programs.find((s) => s.id === programID);
    if (empProgram) {
      newList = datesList.reduce((arr, d) => {
        if (empProgram.testsData && moment().startOf("day").isSameOrBefore(d)) {
          const isTest = empProgram.testsData.find((f) => f.date === d);
          if (isTest) {
            isTest.testTypes.forEach((s) => {
              arr.push({ label: s.label, value: s.value, isDone: false, date: d, location: s.location });
            });
          }
        }
        return arr;
      }, []);

      return { ...empProgram, schedule: newList };
    }
    return null;
  };

  const getProgramByCustomSchedule = (user, datesList) => {
    let newList = [];
    const empProgram = appContext.programs.find((s) => s.id === user.programID);
    if (empProgram || (user.CustomSchedule && user.CustomSchedule.length > 0)) {
      newList = datesList.reduce((arr, d) => {
        const isTest =
          user.CustomSchedule?.find((f) => f.date === d) || empProgram?.testsData.find((f) => f.date === d);
        if (isTest && isTest.testTypes && moment().startOf("day").isSameOrBefore(d)) {
          isTest.testTypes.forEach((s) => {
            arr.push({ label: s.label, value: s.value, isDone: false, date: d, location: s.location });
          });
        }

        return arr;
      }, []);
      return { ...empProgram, schedule: newList };
    }
    return null;
  };

  const uniquePrograms = useMemo(() => {
    const programCalendar = [];
    const datesList = getDaysArray(date.startDate, date.endDate);
    return employees.reduce((arr, user) => {
      let programObj = programCalendar[user.programID];

      // This Condition and Method for used Custom Scheduling
      if (user.CustomSchedule && user.CustomSchedule.length > 0) {
        programObj = getProgramByCustomSchedule(user, datesList);
      }

      if (!programObj) {
        programObj = getProgramBySchedule(user.programID, datesList);
        programCalendar[user.programID] = programObj;
      }

      const userObj = getEmployeeProgramDetails(user, programObj);
      if (userObj) {
        arr.push(userObj);
      }
      return arr;
    }, []);
  }, [employees, showLeveMtarix, dateList, matrixData]);

  const handleApply = async (event, picker) => {
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
    const dateObject = {
      type: showLeveMtarix ? "D" : "Z",
      startDate: moment(picker.startDate).format("YYYY-MM-DD"),
      endDate: moment(picker.endDate).format("YYYY-MM-DD"),
    };
    setDate(dateObject);
  };

  const applySaveView = async () => {
    setLoading(true);
    const dateObject = {
      ...date,
      type: !showLeveMtarix ? "D" : "Z",
    };
    setDate(dateObject);
    saveMatrixDatesInLC.save(dateObject);
    setShowLevelMatrix(!showLeveMtarix);
    setCheckboxes([]);
  };

  const handleCancel = (event, picker) => {
    // picker.element.val("");
    picker.element.val(picker.oldStartDate.format("MM/DD/YYYY") + " - " + picker.oldEndDate.format("MM/DD/YYYY"));
    setDate(logDateRange);
  };

  const handleNotes = (val) => {
    const checks = checkboxes.filter((f) => f !== "Unassigned");
    const checkEdit = checks.filter((f) => f.deptTimeZone || f.color);
    if (checkEdit.length > 1 && checkEdit.some((s) => s.notes)) {
      appContext.showInfoMessage("Please Select one group on Edit");
      return;
    }

    if (
      checks.length > 1 &&
      checkEdit.length === 0 &&
      checks.some((e) => e.notes) &&
      !checks.every((e) => e.notes === checks[0].notes)
    ) {
      appContext.showInfoMessage("Please Select one Emp on Edit");
      return;
    }
    setOpenNotesModal(true);
  };

  const handleAllCheckBoxes = (isSelected) => {
    setSelectAll(isSelected);
    if (isSelected) {
      let program = [];
      if (!showLeveMtarix) {
        program = appContext.programs.filter((f) => filteredPrograms.find((d) => d.programID === f.id));
      } else {
        program = departments.filter((f) => filteredPrograms.find((d) => d.department === f.id));
      }
      setCheckboxes([...filteredPrograms, "Unassigned", ...program]);
    } else {
      setCheckboxes([]);
    }
  };

  const handleGroupCheckboxChange = (e, user, list) => {
    const filteredList = checkboxes.filter((c) => {
      if (user !== "Unassigned") return c.id !== user?.id && list.findIndex((l) => l.id === c.id) === -1;
      return c !== "Unassigned" && list.findIndex((l) => l.id === c.id) === -1;
    });
    if (e.target.checked) {
      const groupSelect = [user || "Unassigned", ...list];
      filteredList.push(...groupSelect);
    }
    setCheckboxes(filteredList);
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c.id !== user.id);
    if (e.target.checked) {
      filteredList.push(user);
    }
    if (!e.target.checked) {
      setSelectAll(false);
    }
    setCheckboxes(filteredList);
  };

  const UpdateValue = () => {
    // console.log({date}, moment(date.startDate).format("MMM DD, YYYY"), moment(date.endDate).format("MMM DD, YYYY"));
    if (date.endDate)
      return {
        startDate: formatDateMDY(date.startDate),
        endDate: formatDateMDY(date.endDate),
        // startDate: date.startDate,
        // endDate: date.endDate,
        minDate: false,
        maxDate: false,
      };
    return {
      autoUpdateInput: false,
      minDate: false,
      maxDate: false,
      locale: {
        format: "DD/MM/YYYY",
        // format: "MMM DD YYYY",
        cancelLabel: "Clear",
      },
    };
  };

  useEffect(() => {
    setStatsData({
      checkInRequire: 0,
      checkInRemaining: 0,
      testRequire: 0,
      testRemaining: 0,
      psqRequire: 0,
      psqRemaining: 0,
      Antigen: 0,
      PCR: 0,
      Molecular: 0,
      Lucira: 0,
      RapidFlu: 0,
      Other: 0,
    });
    refreshDataList(globalSearchText);

    const isExpected = isExpectedLevel();

    const updates = uniquePrograms.reduce((acc, obj) => {
      const todayTest = obj.scheduleData;
      if (
        (obj.checkIn === "0" || obj.checkIn === "1") &&
        todayTest &&
        todayTest.length > 0 &&
        ((isExpected && obj.onSet === 1) || !isExpected)
      ) {
        acc.checkInRequire = (acc.checkInRequire || 0) + 1;
        if (obj.checkIn === "0") {
          acc.checkInRemaining = (acc.checkInRemaining || 0) + 1;
        }
      }
      if (obj.checkIn === "1" && ((isExpected && obj.onSet === 0) || !isExpected)) {
        acc.checkInRequire = (acc.checkInRequire || 0) + 1;
      }
      if (
        (obj.qaDone === "X" || obj.qaDone === "M" || obj.qaDone === "1") &&
        ((isExpected && obj.onSet === 1) || !isExpected)
      ) {
        acc.psqRequire = (acc.psqRequire || 0) + 1;
        if (obj.qaDone === "X") {
          acc.psqRemaining = (acc.psqRemaining || 0) + 1;
        }
      }
      if (todayTest && todayTest.length > 0 && ((isExpected && obj.onSet === 1) || !isExpected)) {
        var result = todayTest.find((item) => item.isDone === true);
        if (result || !result) {
          acc.testRequire = (acc.testRequire || 0) + 1;
        }
        if (!result) {
          acc.testRemaining = (acc.testRemaining || 0) + 1;
        }
      }
      if (todayTest && todayTest.length > 0 && ((isExpected && obj.onSet === 1) || !isExpected)) {
        for (let item of todayTest) {
          if (item.isDone) {
            acc[item.value || item.test_type] = (acc[item.value || item.test_type] || 0) + 1;
          }
        }
      }

      return acc;
    }, {});

    setStatsData((prevStatsData) => ({
      ...prevStatsData,
      ...updates,
    }));
  }, [uniquePrograms]);

  const refreshDataList = (search) => {
    let items = nestedFilter(uniquePrograms, filter);
    if (search) {
      const models = uniquePrograms.filter((obj) => {
        const empFullName = `${obj.firstName} ${obj.lastName}`;
        return (
          obj.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          obj.lastName?.toLowerCase().includes(search.toLowerCase()) ||
          empFullName?.toLowerCase().includes(search.toLowerCase()) ||
          obj.email?.toLowerCase().includes(search.toLowerCase()) ||
          obj.phoneNumber?.toLowerCase().includes(search.toLowerCase()) ||
          obj.programName?.toLowerCase().includes(search.toLowerCase())
        );
      });

      items = models;
    }

    setFilteredPrograms(
      sortData(!showLeveMtarix ? "programID" : "department", true, nestedFilter(items, filter) || [])
    );
    setProgramData(uniquePrograms);
    if (uniquePrograms) setLoading(false);
  };

  const handleSaveGolobalSearch = (search) => {
    setGlobalSearchText(search);
    refreshDataList(search);
  };

  const nestedFilter = (programData, filters) => {
    if (Object.keys(filter).length === 0) return programData;
    const filterKeys = Object.keys(filters);
    const isExpected = isExpectedLevel();
    //filters main array of objects
    const models = programData.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        // console.log({ obj, filter, key });
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (Number(filter[key]) === 0 && key !== "isVaccinated") return obj;
        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "result") {
          return (
            obj.scheduleData &&
            obj.scheduleData.length > 0 &&
            obj.scheduleData.findIndex(
              (test) => filters[key].includes(test.result?.toLowerCase()) && test.date === moment().format("YYYY-MM-DD")
            ) !== -1
          );
        }
        if (key === "isVaccinated") {
          return (obj.isVaccinated ? 1 : 0).toString() === filter[key];
        }
        if (key === "isSchedule") {
          return (obj.isSchedule ? obj.isSchedule : 0) == filters[key];
        }
        if (key === "qaDone") {
          if (filter[key] === "A" && ((isExpected && obj.onSet === 1) || !isExpected)) {
            return obj.qaDone === "1" || obj.qaDone === "X" || obj.qaDone === "M";
          }
          return obj.qaDone === filter[key];
        }
        if (key === "testRequired") {
          return obj.scheduleData && obj.scheduleData.length > 0 && ((isExpected && obj.onSet === 1) || !isExpected);
        }
        if (key === "checkInRequire") {
          return (
            ((obj.checkIn === "1" || obj.checkIn === "0") &&
              obj.scheduleData &&
              obj.scheduleData.length > 0 &&
              ((isExpected && obj.onSet === 1) || !isExpected)) ||
            (obj.checkIn === "1" && ((isExpected && obj.onSet === 0) || !isExpected))
          );
        }
        if (key === "checkInRemaining") {
          return (
            obj.checkIn === "0" &&
            obj.scheduleData &&
            obj.scheduleData.length > 0 &&
            ((isExpected && obj.onSet === 1) || !isExpected)
          );
        }
        if (key === "testAdminister") {
          return (
            obj.scheduleData &&
            obj.scheduleData.length > 0 &&
            obj.scheduleData?.findIndex((f) => f.isDone) !== -1 &&
            ((isExpected && obj.onSet === 1) || !isExpected)
          );
        }
        if (key === "testRemaning") {
          return (
            obj.scheduleData &&
            obj.scheduleData.length > 0 &&
            !obj.scheduleData.find((f) => f.isDone) &&
            ((isExpected && obj.onSet === 1) || !isExpected)
          );
        }
        if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        if (TEST_TYPE_VALUE[key]) {
          return (
            ((isExpected && obj.onSet === 1) || !isExpected) &&
            obj.scheduleData &&
            obj.scheduleData.findIndex((f) => (f.value === key || f.test_type === key) && f.isDone) !== -1
          );
        }
        if (key === "positiveEmployee") {
          if (filters[key] === "Yes" && obj?.testHistory) {
            return (
              obj?.testHistory.filter((t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive")
                .length > 0
            );
          } else if (filters[key] === "No") {
            return (
              !obj?.testHistory ||
              (obj?.testHistory &&
                obj?.testHistory.filter((t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive")
                  .length === 0)
            );
          }
        }
        if (key === "quarantineColor") {
          return filters[key].indexOf(obj[key]) !== -1;
        }
        if (key === "onSetDates") {
          return (
            obj.onSetDates &&
            obj.onSetDates?.dates?.findIndex(
              (d) => new Date(d) > filters[key].startDate._d && new Date(d) < filters[key].endDate._d
            ) !== -1
          );
        }
        if (key === "checkIn") {
          if (filters[key] === "2") {
            return obj.callTimeOver && obj.scheduleData.some((s) => s.date === moment().format("YYYY-MM-DD"));
          }
          return obj.checkIn === filter[key];
        }
        if (key === "driveOnAccess") {
          return obj.driveOnAccess === (Number(filter[key]) ? Number(filter[key]) : Number(filter[key]) || null); //driveOnAccess Data is stored in 'Number' format
        }
        // if (key === "schLocation") {
        if (key === "testLocation") {
          return obj.scheduleData && obj.scheduleData.find((test) => test.location === filter[key]);
        }
        if (key === "onSet") {
          return (
            (isExpected && obj.onSet === (Number(filter[key]) ? Number(filter[key]) : Number(filter[key]) || null)) ||
            !isExpected
          );
        }
        if (key === "todaysQS") {
          return obj.qaDone !== "0";
        }
        if (key === "todaysTest") {
          return obj.testDone === "1" || obj.testDone === "X";
        }
        if (key === "testDone") {
          return obj.testDone === filter[key];
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });

    return models;
  };

  useEffect(() => {
    if (uniquePrograms.length > 0) {
      const filterData = sortData(
        !showLeveMtarix ? "programID" : "department",
        true,
        nestedFilter(uniquePrograms, filter) || []
      );
      setFilteredPrograms(filterData);
      setProgramData(uniquePrograms);
    }
  }, [filter]);

  useEffect(() => {
    if (updatedEmp) {
      const index = filteredPrograms.findIndex((emp) => emp.id === updatedEmp);
      listRef.current.scrollToIndex(index);
      setUpdatedEmp(null);
    }
  }, [filteredPrograms]);

  const handleCustomSch = () => {
    const selectEmps = checkboxes.filter((f) => f !== "Unassigned" && f.hasOwnProperty("schrID"));
    setCustomSchData({ date: moment().format("YYYY-MM-DD"), employees: selectEmps });
  };

  const isMainAdmin = () =>
    appContext.company && appContext.user && appContext.company.contactNumber === appContext.user.phone_number;

  const openClearedForWorkModal = (emp) => {
    setClearWorkEmp([emp]);
    setShowClearedProgram(true);
  };

  const handleClearedProgram = async (isConfirm, reason) => {
    setShowClearedProgram(false);
    if (!isConfirm) return;

    try {
      setLoading(true);
      appContext.showSuccessMessage("Successfully cleared the employee");
      appContext.resetEmployees();
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("Error", err.message);
      setLoading(false);
      appContext.showErrorMessage("Fail to cleared the employee program");
    }
  };

  const handleConfirmDelete = async (isConfirm) => {
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await API.deleteProgramSettings(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetPrograms();
      appContext.showSuccessMessage("program deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleEndQuarantine = async () => {
    try {
      const employeesInQuaratine = checkboxes.filter((u) => u.isQuarantine === 1);
      if (employeesInQuaratine.length === 0) {
        appContext.showErrorMessage("No Record to Process");
        return;
      }

      setLoading(true);
      await API.endQuarantine(
        employeesInQuaratine.map((u) => u.id),
        appContext.user.name
      );
      setLoading(false);
      setCheckboxes([]);
      appContext.showSuccessMessage("Quarantine Ended Successfully");
      appContext.resetEmployees();
    } catch (err) {
      console.log("[handleEndQuarantine]", err);
    }
  };

  const handleRemoveNote = async (emp) => {
    try {
      await API.updateEmpNotes([emp.id], emp.notes);
      const prevEmployees = employees;
      const index = employees.findIndex((e) => e.id === emp.id);
      if (index !== -1) {
        prevEmployees.splice(index, 1, emp);
        setEmployees([...prevEmployees]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <Container fluid className="px-0">
      <Row className="mx-0">
        <Col md="12" className="px-0">
          <Card className="strpied-tabled-with-hover mb-4">
            <Card.Header>
              <Row>
                <Card.Title
                  as="h4"
                  className="col-md-6 col-sm-12"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  H&S Matrix ({formatNumber(filteredPrograms.length)})
                </Card.Title>
              </Row>
              <div className="buttonHeader pe-2 mt-3">
                <div className="d-flex flex-wrap justify-content-center">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={applySaveView}
                    title={!showLeveMtarix ? "Group By Dept" : "Group By Zone"}
                    label={!showLeveMtarix ? "By Dept" : "By Zone"}
                    iconType={!showLeveMtarix ? "groupByDepartmentIcon" : "groupByZoneIcon"}
                  />
                  <Icon
                    handleClick={() => {
                      if (employees && employees.length > 0) {
                        handleAllCheckBoxes(!selectAll);
                      }
                    }}
                    label={!selectAll ? "Select All" : "Deselect All"}
                    title={!selectAll ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  {!appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={handleCustomSch}
                        disabled={
                          checkboxes.filter((f) => f !== "Unassigned" && f.hasOwnProperty("schrID")).length === 0
                        }
                        title="Custom Schedule"
                        label="Schedule"
                        iconType="assignZoneIcon"
                      />

                      <Icon
                        handleClick={() => handleEndQuarantine()}
                        title={"End Quarantine"}
                        label={"X Quarantine"}
                        disabled={
                          checkboxes.length === 0 || checkboxes.filter((u) => u.isQuarantine === 1)?.length === 0
                        }
                        iconType={"endQuarantineIcon"}
                      />
                      <Icon
                        handleClick={() => setOpenNotificationModal(true)}
                        disabled={
                          checkboxes.filter((f) => f !== "Unassigned" && f.hasOwnProperty("schrID")).length === 0
                        }
                        title={"Message"}
                        label={"Message"}
                        iconType="messageIcon"
                      />
                    </>
                  )}
                </div>
                <div className="d-flex" style={{ flexGrow: "100" }}>
                  <div className="d-flex dateSearchBar">
                    <div className="d-flex dateBar">
                      <span className="dateRangeLabel">Showing for: </span>
                      <DateRangePicker
                        ref={dateInputRef}
                        onApply={handleApply}
                        onCancel={handleCancel}
                        initialSettings={UpdateValue()}
                      >
                        <input
                          type="text"
                          placeholder="Select Date Range"
                          readOnly
                          className="dateRangeInputField dateRangeMatrix headerButton"
                          defaultValue=""
                        />
                      </DateRangePicker>
                    </div>
                    <GlobalSearch handleSave={handleSaveGolobalSearch} globalSearchText={globalSearchText} />
                  </div>
                </div>
                {/* </Col> */}
                {/* </Row> */}
              </div>
              {showFilter && (
                <FilterPopOver
                  grouped
                  filterTerms={searchTerms}
                  setFilter={setFilter}
                  matrix={true}
                  filter={filter}
                  triggerFilter={triggerFilter}
                  setTriggerFilter={setTriggerFilter}
                  isZoneLevel={appContext.isZoneLevelCompany()}
                  program={"Zone"}
                />
              )}
              {!appContext.isReadOnly() && (
                <div className="dashboard-wrapper">
                  <span
                    className="linkedText text-decoration-underline float-end"
                    onClick={() => setShowStats(!showStats)}
                  >
                    {!showStats ? "Show Summary" : "Hide Summary"}
                  </span>

                  {showStats && (
                    <div className="d-flex w-100">
                      <div
                        className="stats-item"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <Stats
                          title={"Total Check-In Required"}
                          totalCounts={statsData.checkInRequire}
                          filterKey="checkInRequire"
                          value="1"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Check-In Remaining"}
                          totalCounts={statsData.checkInRemaining}
                          filterKey="checkInRemaining"
                          value="1"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Testing Required"}
                          totalCounts={statsData.testRequire}
                          filterKey="testRequired"
                          value={"1"}
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Testing Remaining"}
                          totalCounts={statsData.testRemaining}
                          filterKey="testRemaning"
                          value={"1"}
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                      </div>
                      <div
                        className="stats-item"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <Stats
                          title={"Total PSQ Required"}
                          totalCounts={statsData.psqRequire}
                          filterKey="qaDone"
                          value="A"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"Total PSQ Remaining"}
                          totalCounts={statsData.psqRemaining}
                          filterKey="qaDone"
                          value="X"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"Total Tests Administered"}
                          totalCounts={statsData.testRequire - statsData.testRemaining}
                          filterKey="testAdminister"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                      </div>
                      <div
                        className="stats-item"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <Stats
                          title={"Antigen"}
                          totalCounts={statsData.Antigen}
                          filterKey="Antigen"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"PCR"}
                          totalCounts={statsData.PCR}
                          filterKey="PCR"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"Cue"}
                          totalCounts={statsData.Molecular}
                          filterKey="Molecular"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"Lucira"}
                          totalCounts={statsData.Lucira}
                          filterKey="Lucira"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"Rapid Flu"}
                          totalCounts={statsData.RapidFlu}
                          filterKey="RapidFlu"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                        <Stats
                          title={"Rapid PCR"}
                          totalCounts={statsData.Other}
                          filterKey="Other"
                          value="1"
                          color="black"
                          handelClick={(obj) => setFilter({ ...obj })}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <MFPagination
                totalSelected={checkboxes.filter((cb) => cb.hasOwnProperty("schrID")).length}
                showAll
                showSelectedRecord
                selectedLabel="Selected Employees"
              />
            </Card.Header>

            <Card.Body className="table-full-width px-0 desktop-noScroll">
              <div className="d-flex">
                <MainTable
                  showLeveMtarix={showLeveMtarix}
                  filteredPrograms={filteredPrograms}
                  handleAllCheckBoxes={handleAllCheckBoxes}
                  appContext={appContext}
                  programData={programData}
                  handleGroupCheckboxChange={handleGroupCheckboxChange}
                  departments={departments}
                  checkboxes={checkboxes}
                  tdWidth={tdWidth}
                  AddNotes={AddNotes}
                  setAddSpecificNotesEmp={setAddSpecificNotesEmp}
                  handleCheckboxChange={handleCheckboxChange}
                  selectAll={selectAll}
                  sortList={sortData}
                  dateList={dateList}
                  setLoading={setLoading}
                  handleUserProfile={handleUserProfile}
                  openClearedForWorkModal={openClearedForWorkModal}
                  showPopover={showPopover}
                  setShowPopover={setShowPopover}
                  deptLeftStickPosition={deptLeftStickPosition}
                  setDeptLeftStickPosition={setDeptLeftStickPosition}
                  zoneLeftStickPosition={zoneLeftStickPosition}
                  setZoneLeftStickPosition={setZoneLeftStickPosition}
                  setShowExternalTestNotification={setShowExternalTestNotification}
                  updatePersonalization={updatePersonalization}
                  showExternalTestData={showExternalTestData}
                  setShowExternalTestData={setShowExternalTestData}
                  testDetailsMatrixData={testDetailsMatrixData}
                  setTestDetailsMatrixData={setTestDetailsMatrixData}
                  viewChanging={viewChanging}
                  setViewChanging={setViewChanging}
                  // secondTableColsWidth={secondTableColsWidth}
                  scrollSaveDelay={scrollSaveDelay}
                  listRef={listRef}
                />
              </div>
            </Card.Body>
          </Card>
          {loading && <Loader />}
          {openNotesModal ||
            (Object.keys(addSpecificNotesEmp).length > 0 && (
              <NotesModal
                handleClose={() => {
                  setOpenNotesModal(false), setAddSpecificNotesEmp({});
                }}
                handleSave={AddNotes}
                employee={addSpecificNotesEmp}
                data={{
                  note: addSpecificNotesEmp?.notes,
                  date: moment().format("YYYY-MM-DD"),
                }}
              />
            ))}
          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete programSettings"
              message="Are you sure, you want to remove program on settings?"
              handleConfirm={handleConfirmDelete}
            />
          )}
          {showClearedProgram && (
            <ClearedProgramModal
              handleConfirm={handleClearedProgram}
              employees={clearWorkEmp}
              appContext={appContext}
            />
          )}
          {openCreator && (
            <EmployeeModal
              cssClass="eos-employeeModal"
              user={employee}
              handleClose={() => {
                setOpenCreator(false);
              }}
              handleSave={updateUserProfile}
              eosModal
              program={defaultProgram}
            />
          )}
          {zoneTestNow && (
            <ZoneTestNowModal
              handleClose={() => setZoneTestNow(false)}
              handleSave={(obj) => handleSaveTestNow(obj)}
              company={appContext.company}
              empIds={checkboxes.filter((f) => f !== "Unassigned" && f.hasOwnProperty("schrID")).map((m) => m.id)}
            />
          )}
          {openNotificationModal && (
            <NotificationInputModal
              data={checkboxes.filter((f) => f !== "Unassigned" && f.hasOwnProperty("schrID"))}
              handleClose={() => setOpenNotificationModal(false)}
              appContext={appContext}
              dataType={NOTIFICATION_FROM.employee}
            />
          )}
          {showExternalTestNotification && (
            <div className="tcMatrix-custom-notification-wrapper">
              <ShowAlertMessage
                message={showExternalTestNotification}
                handleClose={() => setShowExternalTestNotification("")}
                info
              />
            </div>
          )}

          {appContext.isZoneLevelCompany() && showExternalTest && showExternalTestData.visible && (
            <ExternalTestProgramModal
              handleConfirm={handleExternaltest}
              user={showExternalTestData.emp}
              selectedType={"test"}
              companyType={appContext.isZoneLevelCompany()}
            />
          )}
          {appContext.isZoneLevelCompany() && testDetailsMatrixData.visible && showPopover && (
            <TestDetailsMatrixModal
              emp={testDetailsMatrixData.emp}
              dayOpt={testDetailsMatrixData.dayOpt}
              handleClose={handleCloseRemoveTest}
              appContext={appContext}
              date={testDetailsMatrixData.date}
              handleRemoveExternalTest={handleRemoveExternalTest}
            />
          )}
          {customSchData && (
            <CustomScheduleModal
              zone={{ ...customSchData }}
              handleClose={() => {
                setCustomSchData(null);
                appContext.resetEmployees();
                setCheckboxes([]);
                setSelectAll(false);
              }}
              appContext={appContext}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ZoneMatrix;
