import React, { useState, useContext, useEffect } from "react";

// react-bootstrap components
import { Card, InputGroup, FormControl, Form } from "react-bootstrap";
import ColorLabel from "components/ColorLabel";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  STATUS_OPTIONS,
  VACCINATED_OPTIONS,
  QA_OPTIONS,
  TEST_OPTIONS,
  weekDaysOptions,
  CHECKIN_OPTIONS,
  ZONE_STATUS_OPTIONS,
  DRIVE_ACCESS_OPTIONS,
  COLOR_OPT,
  CHECKIN_OPTIONS_MATRIX,
  Quarantine_OPTIONS,
  EXPECTED_ON_SET,
  FILTER_GROUPS,
} from "constant";
import { WEEK_DAYS } from "constant";
import { AppContext } from "../context/app-context";
import { RESULT_OPTIONS } from "constant";
import { LOCATIONS_OPTIONS } from "constant";
import { EXTERNALTEST_OPT } from "constant";
import { TEST_TYPES } from "constant";
import { TEST_LOCATION_OPT } from "constant";
import { t } from "constants/stringConstants";
import GroupLabelTab from "./GroupLabelTab";
import api from "api";
import { CONFIG } from "utils";

const FilterPopOver = ({
  filterTerms,
  setFilter,
  filter,
  isUsersTable,
  triggerFilter,
  setTriggerFilter,
  handleClearFilter,
  isZoneLevel,
  program,
  matrix,
  grouped,
  cssClass,
  eosModal,
  calendar,
}) => {
  const [selectedGroup, setSelectedGroup] = useState(FILTER_GROUPS[0]);
  const [filterData, setFilterData] = useState(filterTerms.map((t) => ({ name: t, groupBy: FILTER_GROUPS[2].value })));
  const appContext = useContext(AppContext);
  const [departmentOpts, setDepartmentOpts] = useState([]);

  useEffect(() => {
    grouped && assignGroup();
    getDepartments();
  }, []);

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartmentOpts(
      data.map((d) => {
        return { label: d.name, value: d.name };
      })
    );
  };

  const colorOptions = () => {
    const unique = [...new Set(appContext.programs.map((item) => item.color))];
    return unique.map((p) => {
      return { value: p, label: <ColorLabel color={p} /> };
    });
  };
  const assignGroup = () => {
    let tempData = filterData;

    filterData.forEach((filter) => {
      switch (filter.name) {
        case "Name":
        case "First Name":
        case "Last Name":
        case "Phone":
        case "Email":
        case "Gender":
        case "isVaccinated":
        case "Local #":
          return (tempData = [
            ...tempData,
            (tempData.find((f) => f.name === filter.name).groupBy = FILTER_GROUPS[0].value),
          ]);
        case "Region":
        case "Location":
        case "Department":
        case `${program} Name`:
        case "Zone Color":
        case "Zone":
        case "Color":
        case "Schedule":
        case "Role":
        case "Test Type":
        case "Screening Type":
        case `${program} Status`:
        case "PSQ":
        case "Test":
        case "Check In":
        case "Call Time":
        case "Job Title":
        case "Type of Test":
        case "Quarantine":
        case "Todays Test":
        case "Todays Question Schedule":
          return (tempData = [
            ...tempData,
            (tempData.find((f) => f.name === filter.name).groupBy = FILTER_GROUPS[1].value),
          ]);
        // case "Crew ID":
        //   return "id";
        // case "Barcode":
        //   return "barcode";
        // case "Status":
        //   return "status";
        // case "Result":
        //   return "result";
        // // case "Lab":
        // //   return "lab";
        // // case "Show":
        // //   return "site_name";
        // case "Contact Name":
        //   return "contact_name";
        // case "Contact Phone":
        //   return "contact_phone";
        // case "Contact Email":
        //   return "contact_email";
        // case "Associated":
        //   return "associated";
        // case "Tester":
        //   return "tester_name";
        // case "Crew Id":
        //   return "employeeID";
        // case "Schedule Type":
        //   return "schType";
        // case "Schedule Name":
        //   return "scheduleName";
        // case "Start Date":
        //   return "startDate";
        // case "End Date":
        //   return "endDate";
        // case "on Set Date Range":
        //   return "onSetDates";
        // case "Group Assigned":
        //   return "groupName";
        // case "Group Name":
        //   return "groupName";
        // case "Drive on Access":
        //   return "driveOnAccess";
        // case "SCHR ID":
        //   return "schrID";
        // case "Days of Weeks":
        //   return "days";
        // case "Time Zone":
        //   return "empTZ";
        // case "Updated At":
        //   return "updatedAt";
        // case "Positive Employee":
        //   return "positiveEmployee";
        // case "Expected on Set":
        //   return "onSet";
        // default:
        //   return tempData = [ ...tempData, tempData.find((f) => f.name === filter.name).groupBy= FILTER_GROUPS[2].value ];
      }
    });

    setFilterData(tempData);
  };
  const filterAlias = (term) => {
    switch (term) {
      case "Crew ID":
        return "id";
      case "Name":
        return "name";
      case "First Name":
        return "firstName";
      case "Last Name":
        return "lastName";
      case "Phone":
        if (isUsersTable) {
          return "phone_number";
        } else {
          return "phoneNumber";
        }
      // return "phoneNumber";
      case "Email":
        return "email";
      case "Sequence":
        return "sequenceNo";
      case "Barcode":
        return "barcode";
      case "Status":
        return "status";
      case "Result":
        return "result";
      // case "Lab":
      //   return "lab";
      // case "Show":
      //   return "site_name";
      case "Role":
        return "role";
      case "Contact Name":
        return "contact_name";
      case "Contact Phone":
        return "contact_phone";
      case "Contact Email":
        return "contact_email";
      case "Date of Birth":
        return "dob";
      case "Test Type":
        return "test_type";
      case "Associated":
        return "associated";
      case "Tester":
        return "tester_name";
      case "Crew Id":
        return "employeeID";
      case "Department":
        return "departmentName";
      case "Schedule Type":
        return "schType";
      case "Schedule Name":
        return "scheduleName";
      case "Start Date":
        return "startDate";
      case "End Date":
        return "endDate";
      case CONFIG.onSetDateRange:
        return "onSetDates";
      case "Group Assigned":
        return "groupName";
      case "Group Name":
        return "groupName";
      case `${program} Name`:
        return "programName";
      case "Zone Color":
        return "zoneColor";
      case "Zone":
        return "zoneSearch";
      case "Color":
        return "color";
      case "Drive on Access":
        return "driveOnAccess";
      case "Schedule":
        return "scheduleName";
      case "Gender":
        return "gender";
      case "isVaccinated":
        return "isVaccinated";
      case "Region":
        return "region";
      case "Location":
        return "location";
      // case "Location":
      //   return "schLocation";
      case "Test Location":
        return "testLocation";
      case "SCHR ID":
        return "schrID";
      case "Screening Type":
        return "testingGroup";
      case `${program} Status`:
        return "isSchedule";
      case "PSQ":
        return "qaDone";
      case "Todays Question Schedule":
        return "todaysQS";
      case "Test":
        return "testDone";
      case "Todays Test":
        return "todaysTest";
      case "Days of Weeks":
        return "days";
      case "Check In":
        return "checkIn";
      case "Call Time":
        return "callTime";
      case "Local #":
        return "localNo";
      case "Job Title":
        return "jobTitle";
      case "Time Zone":
        return "empTZ";
      case "Type of Test":
        return "tests";
      case "Updated At":
        return "updatedAt";
      case "Quarantine":
        return "quarantineColor";
      case "Positive Employee":
        return "positiveEmployee";
      case CONFIG.expectedOnSet:
        return "onSet";
      case "External Test":
        return "isExternalTest";
      default:
        return term;
    }
  };
  const getCheckBox = (filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    return (
      <>
        <div className="filterInputBar my-auto">
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              checked={prevData === "1"}
              title={`${prevData ? `${filterTerm}: checked` : `${filterTerm}: unchecked`}`}
              value={prevData || 0}
              className="my-auto cursor-pointer"
              onChange={(e) => {
                setFilter({
                  ...filter,
                  [filterAlias(filterTerm)]: e.target.checked ? "1" : "0",
                });
              }}
            />
          </Form.Check>
        </div>
      </>
    );
  };
  const getDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        isClearable={true}
        className="filterInputBar"
        defaultValue={null}
        menuPlacement="auto"
        placeholder={`Select ${filterTerm}`}
        value={prevSelectedVal && prevSelectedVal.length > 0 ? prevSelectedVal[0] : null}
        onChange={(e) => {
          if (e) {
            setFilter({
              ...filter,
              [filterAlias(filterTerm)]: e.value,
            });
          } else {
            const filterList = { ...filter };
            delete filterList[filterAlias(filterTerm)];
            setFilter(filterList);
          }
        }}
      />
    );
  };
  const getMultiDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="filterInputBar"
        defaultValue={null}
        menuPlacement="auto"
        isMulti
        placeholder={`Select ${filterTerm}`}
        value={prevSelectedVal}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.map((t) => t.value),
          });
        }}
      />
    );
  };
  const getMultiDropDownWithHiddenInput = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)] || [];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="filterInputBar"
        defaultValue={null}
        menuPlacement="auto"
        closeMenuOnSelect={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isMulti
        placeholder={prevData.length ? `${prevData.length} selected` : `Select ${filterTerm}`}
        value={prevSelectedVal}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.map((t) => t.value),
          });
        }}
        styles={{
          option: (base, props) => {
            if (base.backgroundColor === "#2684FF") {
              const backgroundColor = "#e0effe";
              const color = "black";
              return { ...base, backgroundColor, color };
            }
            return base;
          },
          // option: (base, props) => console.log(base, props),
        }}
      />
    );
  };
  const lowerCaseKeys = (obj) => {
    const keys = Object.keys(obj);
    const newObj = {};
    for (const key of keys) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };
  const dateRangeSelection = (filterTerm) => (
    <DateRangePicker
      onApply={(event, picker) => {
        picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: { startDate: picker.startDate, endDate: picker.endDate },
        });
      }}
      onCancel={(event, picker) => {
        picker.element.val("");
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {},
        });
      }}
      initialSettings={
        filter[filterAlias(filterTerm)]
          ? filter[filterAlias(filterTerm)]
          : {
              drops: "auto",
              autoUpdateInput: false,
              locale: {
                cancelLabel: "Clear",
              },
            }
      }
    >
      <input
        type="text"
        placeholder="Select Date Range"
        readOnly
        style={{ maxWidth: "100%" }}
        className="filterInputBar dateRangeFilter filerInputField clearFilter"
        defaultValue=""
      />
    </DateRangePicker>
  );
  const filterContent = (filterTerm) => {
    switch (filterTerm) {
      case `${program} Name`:
        const options = appContext.programs.map((p) => {
          return { value: p.name, label: p.name };
        });
        return eosModal ? getMultiDropDownWithHiddenInput(options, filterTerm) : getMultiDropDown(options, filterTerm);
      case "Zone Color":
        return getMultiDropDown(colorOptions(), filterTerm);
      case "Color":
        return getMultiDropDown(colorOptions(), filterTerm);
      case "Quarantine":
        return getMultiDropDown(Quarantine_OPTIONS, filterTerm);
      case "Result":
        return getMultiDropDown(RESULT_OPTIONS, filterTerm);
      case `${program} Status`:
        return getDropDown(isZoneLevel ? ZONE_STATUS_OPTIONS : STATUS_OPTIONS, filterTerm);
      case "isVaccinated":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "Test Location":
        return getDropDown(TEST_LOCATION_OPT, filterTerm);
      case "Test":
        return getDropDown(TEST_OPTIONS, filterTerm);
      case "External Test":
        return getDropDown(EXTERNALTEST_OPT, filterTerm);
      case "Department":
        return getDropDown(departmentOpts, filterTerm);
      case "Test Type":
        return getMultiDropDown(TEST_TYPES, filterTerm);
      case "Todays Test":
        return getCheckBox(filterTerm);
      case "Todays Question Schedule":
        return getCheckBox(filterTerm);
      case "PSQ":
        return getDropDown(QA_OPTIONS, filterTerm);
      case "Location":
        return getDropDown(
          appContext.locations.map((m) => ({ value: m.name, label: m.name })),
          filterTerm
        );
      case "Region":
        return getDropDown(
          appContext.regions.map((m) => ({ value: m.name, label: m.name })),
          filterTerm
        );
      case "Days of Weeks":
        return getMultiDropDown(weekDaysOptions, filterTerm);
      case "Start Date":
        return dateRangeSelection(filterTerm);
      case "End Date":
        return dateRangeSelection(filterTerm);
      case "Updated At":
        return dateRangeSelection(filterTerm);
      case CONFIG.onSetDateRange:
        return dateRangeSelection(filterTerm);
      case "Check In":
        return getDropDown(matrix ? CHECKIN_OPTIONS_MATRIX : CHECKIN_OPTIONS, filterTerm);
      case "Drive on Access":
        return getDropDown(DRIVE_ACCESS_OPTIONS, filterTerm);
      case CONFIG.expectedOnSet:
        return getDropDown(EXPECTED_ON_SET, filterTerm);

      case "Positive Employee":
        return getDropDown(
          [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ],
          filterTerm
        );
      default:
        return (
          <FormControl
            className="filterInputBar simpleInputBar clearFilter"
            placeholder={`Enter ${filterTerm}`}
            onChange={(e) => {
              setFilter({
                ...filter,
                [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
              });
            }}
            value={filter[filterAlias(filterTerm)]}
          />
        );
    }
  };
  const renderFilters = (filters) => {
    return filters.map((f) => {
      return grouped ? (
        f.groupBy === selectedGroup.value && (
          <InputGroup key={f.name} className="FormInputGroup d-flex flex-column">
            {triggerFilter && (setFilter({ ...filter }), setTriggerFilter(false))}
            <span className="ellipsis" style={{ minWidth: "100%" }}>
              {f.name}
            </span>
            {filterContent(f.name)}
          </InputGroup>
        )
      ) : (
        <InputGroup key={f} className="FormInputGroup d-flex flex-column">
          {triggerFilter && (setFilter({ ...filter }), setTriggerFilter(false))}
          <span className="ellipsis" style={{ minWidth: "100%" }}>
            {f}
          </span>
          {filterContent(f)}
        </InputGroup>
      );
    });
  };

  return (
    <div className={`filter-component-div my-2 ${cssClass}`}>
      {!calendar && (
        <div className={`${grouped ? "d-block d-sm-flex justify-content-between pt-2" : ""}`}>
          {grouped && (
            <GroupLabelTab
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              groups={FILTER_GROUPS}
              filterLabel
            />
          )}
          <div className="filterResetBtn mx-4">
            <span
              onClick={() => {
                Array.from(document.getElementsByClassName("clearFilter")).forEach((input) => (input.value = ""));
                setFilter({});
                if (handleClearFilter) handleClearFilter();
              }}
              className="linkedText textUnderLine"
            >
              Clear Filter
            </span>
          </div>
        </div>
      )}
      <div className={`${grouped ? "mt-3" : ""} ${eosModal ? "pt-0" : ""} form-parent-container`}>
        {grouped ? renderFilters(filterData) : renderFilters(filterTerms)}
      </div>
    </div>
  );
};

export default FilterPopOver;
