import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Badge from "components/Badge";
import { DROPDOWN_OPT } from "constant";
import DropdownMenu from "components/DropdownMenu";

const CustomSchDetailItems = (props) => {
  const { item, handleCheck, checkboxes, handleRemoveTest, index, handleResetCustomSch, handleReSchedule } = props;
  const handleRemoveTestTypes = (label) => {
    console.log("label", label);
    handleRemoveTest(label, item.id, index);
  };

  const handleDropDownOptions = (test, key) => {
    handleReSchedule(test, key, item.id, index);
  };

  return (
    <tr>
      <td className="ellipsis" style={{ textAlign: "left", width: "10%" }}>
        <Form.Check>
          <Form.Check.Input
            type="checkBox"
            id={item.id}
            onChange={(e) => handleCheck(e, item.id)}
            value={item.id}
            checked={checkboxes.includes(item.id)}
          ></Form.Check.Input>
        </Form.Check>
      </td>
      <td className="tdWidth12 ellipsis">{item.firstName}</td>
      <td className="tdWidth12 ellipsis">{item.lastName}</td>
      <td className="tdWidth12 ellipsis">{item.email}</td>
      <td className="tdWidth12 ellipsis">{item.phoneNumber}</td>
      <td className="tdWidth12 ellipsis">{item.zone?.name || ""}</td>
    </tr>
  );
};

export default CustomSchDetailItems;
