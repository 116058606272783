import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Modal, Card, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import GlobalSearch from "components/GlobalSearch";
import { TEST_TYPES } from "constant";
import TDNameWithDetail from "components/TDNameWithDetail";
import { LOCATIONS_OPTIONS } from "constant";
import ViewportList from "react-viewport-list";
import { formatEmployeesForCustomSch } from "utils";
import CustomSchDetailItems from "./CustomSchDetailItems";
import { formatDateMDY } from "utils";
import api from "api";
import debounce from "debounce";
import moment from "moment";
import DatePicker from "react-date-picker";
import RadioButton from "components/RadioButton/RadioButton";
import API from "api";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "../ConfirmationModal";
import { CUSTOM_SCH } from "constant";
import { CONFIRMATION_TYPE } from "constant";
import { formatNumber } from "utils";
import Badge from "components/Badge";

const CustomScheduleModal = (props) => {
  const { handleSave, handleClose, zone, appContext, empsToUpdate, cssClass, expectedOnSet } = props;
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [users, setUsers] = useState([]);
  const [date, setDate] = useState(zone.date);
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [error, setError] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(LOCATIONS_OPTIONS[1]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [assignTest, setAssignTest] = useState(null);
  const [overWrite, setOverWrite] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState({ isShow: false });

  useEffect(() => {
    if (zone.employees && zone.employees.length > 0) {
      setFilterEmployees(formatEmployeesForCustomSch(JSON.stringify(zone), zone.date, appContext.programs));
      setUsers(formatEmployeesForCustomSch(JSON.stringify(zone), zone.date, appContext.programs));
    }
  }, [zone.date, appContext.programs]);

  const saveCustomSch = useCallback(
    debounce((emps, date, setting, key) => {
      handleUpdateSch(emps, date, setting, key);
    }, 500),
    []
  );

  useEffect(() => {
    if (expectedOnSet && empsToUpdate && empsToUpdate.length > 0) {
      setCheckboxes(empsToUpdate.map((emp) => emp.id));
    }
  }, [empsToUpdate]);

  const handleUpdateSch = async (emps, date, setting, key) => {
    try {
      if (CUSTOM_SCH.reset === key) {
        await api.removeCustomSch(emps, date, setting);
      } else {
        await api.updateCustomSchedule(emps, date, setting);
        if (CUSTOM_SCH.add === key) {
          appContext.showSuccessMessage("Custom schedule updated successfully!");
          handleClose();
          setLoading(false);
        }
      }
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const onHandleSave = () => {
    if (!assignTest) {
      setError("Please Select Test Type");
      return;
    }
    if (!selectedLocation) {
      setError("Please Select Location");
      return;
    }
    if (checkboxes.length === 0) {
      setError("Please Select at least one employee");
      return;
    }
    if (!date || date == "Invalid date") {
      setError("Please Select Date");
      return;
    }
    if (!overWrite) {
      setError("Please Select Replace Test Yes or No");
      return;
    }
    if (
      assignTest &&
      assignTest.find((f) => ["PCR", "Cue"].includes(f.label)) &&
      selectedLocation.label === "At Home"
    ) {
      setError("Cue and PCR Test must be On Set");
      return;
    }
    if (appContext.company.workingDays.findIndex((d) => d.date === date) !== -1) {
      setShowConfirmationMessage({
        isShow: true,
        message: `Are You Sure, Add Custom Schedule at ${formatDateMDY(date)} `,
        actionType: CONFIRMATION_TYPE.ADD_CUSTOM_SCH,
        title: "ADD Custom Schedule",
      });
    } else {
      setShowConfirmationMessage({
        isShow: true,
        message: `${formatDateMDY(date)} is not a working day. Do you want to enable it?`,
        actionType: CONFIRMATION_TYPE.WORKING_DAY,
        title: "Update Working Day",
      });
    }
  };

  const handleUpdateBlockOutDate = async (dates, workingDate) => {
    try {
      await API.updateBlockOut(dates, workingDate);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const handleConfirm = (isConfirm, actionType) => {
    setShowConfirmationMessage({ isShow: false });
    if (!isConfirm) {
      return;
    }
    const employees = formatTest(assignTest, checkboxes);
    switch (actionType) {
      case CONFIRMATION_TYPE.ADD_CUSTOM_SCH:
        handleUpdateCustomSch(employees, date);
        break;
      case CONFIRMATION_TYPE.WORKING_DAY:
        const workingDates = [...(appContext.company?.workingDays || [])];
        workingDates.push({ date: date });
        handleUpdateBlockOutDate(appContext.company.blockOutDays, workingDates);
        handleUpdateCustomSch(employees, date);
        break;
      default:
        return;
    }
  };

  const handleUpdateCustomSch = async (employees, date) => {
    try {
      setLoading(true);
      saveCustomSch(employees, date, appContext.company, CUSTOM_SCH.add);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
      console.log("err", err.message);
    }
  };

  const formatTest = (tests, empIDs) => {
    return empIDs.map((id) => {
      const emp =
        date === zone.date
          ? users.find((f) => f.id === id)
          : formatEmployeesForCustomSch(JSON.stringify(zone), date, appContext.programs).find((f) => f.id === id);
      let testData = [];
      if (overWrite === "Yes") {
        testData = tests.map((m) => ({ ...m, date, location: selectedLocation.value }));
      } else {
        testData = tests.reduce(
          (arr, obj) => {
            const findTest = arr.find((f) => f.value === obj.value);
            if (!findTest) {
              arr.push({ ...obj, date, location: selectedLocation.value });
            } else if (findTest && findTest.location !== selectedLocation.value) {
              const testIndex = arr.findIndex((f) => f.value === findTest.value);
              arr.splice(testIndex, 1, { ...obj, date, location: selectedLocation.value });
            }

            return arr;
          },
          [...emp.testTypes]
        );
      }

      return { ...emp, testTypes: testData };
    });
  };

  const handleRemoveTestTypes = async (testType, empId, index) => {
    const employee = filterEmployees.find((f) => f.id === empId);
    employee.testTypes = employee.testTypes.filter((f) => f.label !== testType);
    if (
      employee.zoneTests.length === employee.testTypes.length &&
      employee.zoneTests.every(
        (t) => employee.testTypes.findIndex((f) => f.value === t.value && f.location === t.location) !== -1
      )
    ) {
      employee.customTest = false;
    } else {
      employee.customTest = true;
    }
    const employees = [...filterEmployees];
    const usersData = [...users];
    const userIndex = usersData.findIndex((f) => f.id === employee.id);
    employees.splice(index, 1, employee);
    usersData.splice(userIndex, 1, employee);
    setFilterEmployees(employees);
    setUsers(usersData);

    saveCustomSch([employee], zone.date, appContext.company, CUSTOM_SCH.update);
  };

  const handleResetCustomSch = async (empId, index) => {
    const employee = filterEmployees.find((f) => f.id === empId);

    employee.testTypes = [...employee.zoneTests];
    employee.customTest = false;
    const employees = [...filterEmployees];
    const usersData = [...users];
    const userIndex = usersData.findIndex((f) => f.id === employee.id);
    employees.splice(index, 1, employee);
    usersData.splice(userIndex, 1, employee);
    setFilterEmployees(employees);
    setUsers(usersData);
    saveCustomSch(employee, zone.date, appContext.company, CUSTOM_SCH.reset);
  };

  const handleReSchedule = async (testType, key, empId, index) => {
    const employee = filterEmployees.find((f) => f.id === empId);
    employee.testTypes = employee.testTypes = employee.testTypes.filter((f) => f.label !== testType);
    if (
      employee.zoneTests.length === employee.testTypes.length &&
      employee.zoneTests.every(
        (t) => employee.testTypes.findIndex((f) => f.value === t.value && f.location === t.location) !== -1
      )
    ) {
      employee.customTest = false;
    } else {
      employee.customTest = true;
    }
  };

  const handleSaveGlobalSearch = (search) => {
    setGlobalSearchText(search);
    refreshDataList(search);
  };

  const refreshDataList = (search) => {
    let employees = [...users];
    if (search) {
      const models = employees.filter((emp) => {
        const empName = `${emp.firstName} ${emp.lastName}`;
        return empName?.toLowerCase().includes(search.toLowerCase());
      });
      employees = models;
    }
    setFilterEmployees(employees);
  };

  console.log("zone", zone);
  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      setCheckboxes((prevID) => [...prevID, id]);
    } else {
      const filterIds = checkboxes.filter((f) => f !== id);
      setCheckboxes(filterIds);
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setCheckboxes(filterEmployees.map((m) => m.id));
    } else {
      setCheckboxes([]);
    }
  };
  const getRandomNumber = (max) => {
    const min = Math.floor(max / 2);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const Invitations = zone.employees.length;
  const Confirmed = getRandomNumber(Invitations);
  const checkIn = getRandomNumber(Confirmed);

  const notCheckIn = Confirmed - checkIn;
  const notConfirmed = Invitations - Confirmed;

  return (
    <Modal
      show
      onHide={handleClose}
      animation={true}
      size={expectedOnSet ? "md" : "xl"}
      scrollable
      centered
      keyboard={true}
      className={cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0">{zone.eventName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-around">
          <Badge label={`Total Invitations : ${Invitations}`} />
          <Badge label={`Total Confirmed : ${Confirmed}`} />
          <Badge label={`Total Not Confirmed : ${notConfirmed}`} />
          <Badge label={`Total Check In Today : ${checkIn}`} />
          <Badge label={`Total Not Check In Today : ${notCheckIn}`} />
        </div>

        {!expectedOnSet && (
          <>
            <strong>{`Selected Employees (${formatNumber(checkboxes.length)})`}</strong>
            <Card className="strpied-tabled-with-hover">
              <Card.Body className="table-full-width desktop-noScroll">
                <div className={"table-responsive pendingReleaseTable"}>
                  <Table className="table">
                    <thead>
                      <tr>
                        <Form.Check className="mt-2 ps-0 tdWidth9">
                          <Form.Check.Input
                            type="checkBox"
                            id={"all"}
                            onChange={(e) => handleCheckAll(e)}
                            checked={filterEmployees.every((f) => checkboxes.includes(f.id))}
                          ></Form.Check.Input>
                        </Form.Check>

                        <th className="tdWidth12">First Name</th>
                        <th className="tdWidth12">Last Name</th>
                        <th className="tdWidth12">Email</th>
                        <th className="tdWidth12">Phone</th>
                        <th className="tdWidth12">Zone Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterEmployees.length > 0 && (
                        <ViewportList
                          items={filterEmployees}
                          overscan={10}
                          itemMinSize={42}
                          margin={16}
                          overflowAnchor="auto"
                          withCache={false}
                          scrollThreshold={500}
                        >
                          {(emp, index) => (
                            <CustomSchDetailItems
                              key={emp.id}
                              handleCheck={handleCheckBox}
                              item={emp}
                              index={index}
                              checkboxes={checkboxes}
                              handleRemoveTest={handleRemoveTestTypes}
                              handleResetCustomSch={handleResetCustomSch}
                              handleReSchedule={handleReSchedule}
                            />
                          )}
                        </ViewportList>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </>
        )}
      </Modal.Body>
      <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        {assignTest && (
          <Button variant="secondary" className="headerButton btn-fill" onClick={onHandleSave}>
            Save
          </Button>
        )}
      </Modal.Footer>
      {loading && <Loader />}
      {showConfirmationMessage.isShow && (
        <ConfirmationModal
          show={true}
          title={showConfirmationMessage.title}
          actionType={showConfirmationMessage.actionType}
          message={showConfirmationMessage.message}
          handleConfirm={handleConfirm}
          closeBtn
          cssClass={"eos-employeeModal"}
        />
      )}
    </Modal>
  );
};

export default CustomScheduleModal;
