export const TESTING_SCHEDULE = "Testing Schedule";
export const QUESTION_SCHEDULE = "Question Schedule";
export const QUESTION_SCHEDULE_LIST = "Pre-Screening Schedule";
export const HEALTH_SAFETY_SCHEDULE = "Health-Screening Question Groups";
export const EMPLOYEE_DEPARTMENTS = "Department Setup";
export const LOCATIONS = "Locations";
export const REGIONS = "Regions";
export const PROGRAMS = "Programs";
export const ZONES = "Event Setup";
export const MESSAGE_SETTINGS = "Alerts/Messages";
export const TIMER_TEST = "Timer Test";
export const PROXY_CONTACT_OPTIONS = "Proxy Contact options";
