import React from "react";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";

const QuestionnaireViewModal = ({ item, handleClose }) => {
  const TableRow = ({ question, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{question.name}</td>
        <td style={{ textAlign: "center" }}>{question.answer}</td>
      </tr>
    );
  };

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Health-Screening Questionnaire
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <div style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Row>
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "#565656",
                      }}
                    >
                      Group Name: {item.groupName.toUpperCase()}
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div
                      className="table-responsive"
                      style={{
                        overflowY: "scroll",
                        height: "400px",
                        overflowX: "hidden",
                      }}
                    >
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Sr#</th>
                            <th>Question</th>
                            <th className="centered">Expected Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.question &&
                            item.question.question.map((item, i) => {
                              return <TableRow key={i} index={i} question={item} />;
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons headerButton btn-fill btn btn-primary" onClick={() => handleClose()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionnaireViewModal;
