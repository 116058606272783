import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import { Modal, Form, Button, Row, Card, Col, Table, Overlay, Popover } from "react-bootstrap";
import { getValidReg, removeSpaceIns, formatDateMDY, getDaysArray, differenceBtwDays } from "utils";
import { COLOR_OPT, TEST_TYPES, TEST_TYPE_VALUE, weekDaysOptions, DAYS_LABEL } from "constant";
import api from "api";
import { AppContext } from "context/app-context";
import { SketchPicker } from "react-color";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ShowCalenderView from "components/ShowCalenderView";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMemo } from "react";
import Status from "components/Status/Status";
import workingDayOnIcon from "../../../src/assets/img/film.png";
import workingDayOffIcon from "../../../src/assets/img/film-gray.png";
import notesIcon from "../../../src/assets/img/file-icon.png";
import { CONFIRMATION_TYPE } from "constant";
import ConfirmationModal from "./ConfirmationModal";
import { setCharAt } from "utils";
import { LOCATIONS_OPTIONS } from "constant";
import { debounce } from "debounce";
import EventsPopOver from "./EventsPopOver";
import { EVENTS_LIMIT_PER_CELL } from "constant";
import { removeArrDuplicateObjs, eventTypes } from "utils";
import { sortWorkingDays } from "utils";
import EventModal from "./EventModal";

const ZoneModalCalendar = (props) => {
  const { handleConfirm, programSetting, programs, handleClose, selectItem, handleSave, program, questionSchedule } =
    props;
  const [newProgram, setNewProgram] = useState(selectItem);
  const [addDays, setAddDays] = useState(1);
  const [error, setError] = useState("");
  const [addTestType, setAddtestType] = useState("");
  const [addGroup, setaddGroup] = useState(selectItem?.testValidity || []);
  const appContext = useContext(AppContext);
  const [showPopover, setShowPopover] = useState(false);
  const [events, setEvents] = useState([]);
  const [testingCalendar, setTestingCalendar] = useState(true);
  const [workingDates, setWorkingDates] = useState([]);
  const [updatedWorkingDates, setUpdatedWorkingDates] = useState([]);
  const childNode = useRef();
  const localizer = momentLocalizer(moment);
  const [blockOutDate, setBlockOutDate] = useState([]);
  const [quesSch, setQuesSch] = useState(null);
  const [qaSchDates, setQaSchDates] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [objToSave, setObjToSave] = useState(null);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [questionGroupOptions, setQuestionGroupOptions] = useState([]);
  const [selectedQuestionGroup, setSelectedQuestionGroup] = useState(null);
  const [selectedQuestionData, setSelectedQuestionData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(LOCATIONS_OPTIONS[1]);
  const [showMorePopUp, setShowMorePopUp] = useState(false);
  const [showMoreEvents, setShowMoreEvents] = useState([]);
  const [showMoreDate, setShowMoreDate] = useState("");
  const [eventModal, showEventModal] = useState(false);
  const [eventNames, setEventNames] = useState(eventTypes.get());

  useEffect(() => {
    const temp = [];
    const questions = appContext.questions || [];

    questions.map((q) =>
      temp.push({
        value: q.id,
        label: q.groupName,
      })
    );

    setQuestionGroupOptions(temp);
    setQuestionGroups(questions);
  }, [appContext.questions]);

  useEffect(() => {
    if (appContext.company) {
      setBlockOutDate(appContext.company.blockOutDays || []);
      setWorkingDates(workingDates.length > 0 ? workingDates : appContext.company.workingDays || []);
      setUpdatedWorkingDates(
        updatedWorkingDates.length > 0 ? updatedWorkingDates : appContext.company.workingDays || []
      );
      const filterData = events.filter(
        (f) =>
          updatedWorkingDates?.find((e) => e.date === f.start) ||
          appContext.company.workingDays.find((e) => e.date === f.start)
      );
      setEvents(filterData);
    }
  }, [appContext.company]);

  useEffect(() => {
    if (appContext.schedules.length > 0) {
      if (quesSch) {
        const sch = appContext.schedules.find((f) => f.id === quesSch.value);
        if (sch) {
          setQaSchDates(sch.dayOptions);
        } else [setQaSchDates([])];
      } else if (newProgram && newProgram.scheduleID) {
        const sch = appContext.schedules.find((f) => f.id === newProgram.scheduleID);
        if (sch) setQaSchDates(sch.dayOptions);
      }
    }
  }, [quesSch, newProgram, appContext.schedules]);

  const isExistName = () => {
    const isExist = programs.filter(
      (d) => d.id !== newProgram?.id && removeSpaceIns(d.name) == removeSpaceIns(newProgram.name)
    );

    if (isExist && isExist.length > 0) return true;
    return false;
  };

  const customDateHeader = (props) => {
    const dateFormat = moment(props.date).format("YYYY-MM-DD");
    const cell = blockOutDate.find((f) => f.notesDate === dateFormat);
    const workDay = workingDates.find((f) => f.date === dateFormat);
    const currentCellBlocked = blockOutDate.find(
      (f) => f.date === dateFormat && f.date === moment().format("YYYY-MM-DD")
    );
    return (
      <div
        style={{
          backgroundColor: `${currentCellBlocked && "#898483"}`,
          borderBottom: `${currentCellBlocked && "1px solid #898483"}`,
          marginRight: `${currentCellBlocked && "-5px"}`,
        }}
        className={`custom-date-header d-flex justify-content-between py-1 ${
          currentCellBlocked ? "blocked-date-header" : ""
        }`}
        onClick={() => handleSelectSlot({ start: props.date, end: moment(props.date).add(1, "day")._d })}
      >
        <div className="dh-item header-left">
          <span
            className="mx-2"
            onClick={(e) => {
              e.stopPropagation();
              handleWorkingDate(dateFormat);
            }}
          >
            {workDay ? <i className="fas fa-building green-icon" /> : <i className="fas fa-building grey-icon" />}
          </span>
          {cell && (
            <span className="mx-2" title={cell.note}>
              <img className="notesIcon" src={notesIcon} alt="notes-img" />
            </span>
          )}
        </div>
        <div className="dh-item header-right mx-1">{props.label}</div>
      </div>
    );
  };

  const customEventContainer = (event) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span>{event.title}</span>
        {event.event.location === "At Home" ? <i className="fa fa-home" /> : ""}
      </div>
    );
  };

  const QuestionScheduleOptions = useMemo(() => {
    const schOptions = questionSchedule.map((o) => ({ value: o.id, label: o.scheduleName }));
    if (schOptions.length === questionSchedule.length) {
      schOptions.unshift({ value: null, label: "No Schedule" });
    }
    return schOptions;
  }, [questionSchedule]);

  useEffect(() => {
    if (selectItem?.testsData) {
      let quesData = [];
      const prevEvent = selectItem.testsData.reduce((arr, obj) => {
        if (obj.question) quesData.push(obj.question);
        if (obj && obj.testTypes) {
          obj.testTypes.forEach((d) => {
            arr.push({
              start: obj.date,
              end: moment(obj.date).add(1, "day").format("YYYY-MM-DD"),
              title: d.value,
              location: d.location,
            });
          });
        }
        return arr;
      }, []);
      setEvents(prevEvent);

      // console.log(prevEvent.length, { prevEvent });
      // const jsonObject = prevEvent.map((e) => JSON.stringify(e));
      // const uniqueEvents = new Set(jsonObject);
      // const nonDupEvents = Array.from(uniqueEvents).map((e) => JSON.parse(e));
      // setEvents(nonDupEvents);

      setSelectedQuestionData(quesData);
    }

    if (selectItem?.name) {
      setAddtestType(selectItem?.name);
    }
    // if (selectItem?.scheduleType === "month") {
    //   setTestingCalendar(true);
    // } else {
    //   setTestingCalendar(false);
    // }
  }, [selectItem]);

  // console.log(events.length, { events });

  useEffect(() => {
    if (selectItem?.scheduleID) {
      const question = appContext.questions.find((ques) => ques.id === selectItem?.scheduleID);
      if (question) {
        setSelectedQuestionGroup({ value: question.id, label: question.groupName });
      }
    }
  }, [selectItem, appContext.question]);

  const handleSelectEvent = (event) => {
    const findVal = events.findIndex(
      (f) =>
        f.start === event.start &&
        // TEST_TYPE_VALUE[f.title] === event.title &&
        TEST_TYPE_VALUE[f.title] === TEST_TYPE_VALUE[event.title] &&
        (!selectItem || moment().startOf("day").isSameOrBefore(f.start))
    );
    if (findVal !== -1) {
      const arrObj = [...events];
      arrObj.splice(findVal, 1);
      setEvents(arrObj);
      updateWorkingDays(event.start, arrObj);
    }
  };

  // const handleSelectSlot = debounce((event) => {
  //   selectSlot(event);
  // }, 230);

  const handleSelectSlot = (event) => {
    if (event.slots?.length > 1) return;
    const startDate = moment(event.start).format("YYYY-MM-DD");

    if (moment().startOf("day").isAfter(startDate)) {
      setError("Past schedule can not be modified");
      return;
    }

    // if (event.action === "doubleClick") {
    //   if (!selectedQuestionGroup) {
    //     setError("Please select Question Group");
    //     return;
    //   }
    //   const findVal = selectedQuestionData.findIndex((q) => q.date === startDate);
    //   if (findVal !== -1) {
    //     // setError("Selected Date already has question assigned to it!");
    //     // return;
    //     const temp = [...selectedQuestionData];
    //     temp.splice(findVal, 1);
    //     setSelectedQuestionData(temp);
    //     return;
    //   }

    //   setSelectedQuestionData([...selectedQuestionData, { date: startDate }]);
    // } else {
    if (!addTestType) {
      setError("Please Select Event");
      return;
    }

    if (Object.keys(selectedLocation).length === 0) {
      setError("Please select Location");
      return;
    }
    if ((addTestType === "PCR" || addTestType === "Molecular") && selectedLocation.value === "At Home") {
      setError(`${TEST_TYPE_VALUE[addTestType]} Test must be On Set`);
      return;
    }

    const findVal = events.findIndex((f) => f.start === startDate && f.title === addTestType);

    if (findVal === -1) {
      const tempEvents = [...events];
      tempEvents.push({
        start: startDate,
        end: moment(event.end).format("YYYY-MM-DD"),
        title: addTestType,
        location: selectedLocation.value,
      });

      setEvents(tempEvents);

      // setEvents((prev) => [
      //   ...prev,
      //   {
      //     start: startDate,
      //     end: moment(event.end).format("YYYY-MM-DD"),
      //     title: addTestType,
      //     location: selectedLocation.value,
      //   },
      // ]);
      updateWorkingDays(startDate, tempEvents);
    }
    // }

    // const findWorkingDate = workingDates.findIndex((f) => f.date === date);
    // if (findWorkingDate === -1) {
    //   const dates = [...updatedWorkingDates];
    //   const dateIndex = dates.findIndex((f) => f.date === date);
    //   if (dateIndex === -1) {
    //     dates.push({ date });
    //     setUpdatedWorkingDates(dates);
    //   }
    // } else {
    //   const findIndex = updatedWorkingDates.findIndex((d) => d.date === date);
    //   if (findIndex !== -1) {
    //     const findEvent = events.findIndex((e) => e.start === date);
    //     if (findEvent === -1) {
    //       const upWorkingDates = [...updatedWorkingDates];
    //       upWorkingDates.splice(findIndex, 1);
    //       setUpdatedWorkingDates(upWorkingDates);
    //     }
    //   }
    // }
  };

  const updateWorkingDays = (
    date,
    eventsArr = events,
    questionData = selectedQuestionData,
    workingDays = workingDates
  ) => {
    const dates = [...updatedWorkingDates];
    const dateIndex = dates.findIndex((f) => f.date === date);

    const findWorkingDate = workingDays.findIndex((f) => f.date === date);

    const findEvent = eventsArr.findIndex((e) => e.start === date);

    const findQuestionAssigned = questionData.findIndex((q) => q.date === date);

    if (findWorkingDate === -1) {
      if (dateIndex === -1 && (findEvent !== -1 || findQuestionAssigned !== -1)) {
        dates.push({ date });
        setUpdatedWorkingDates(dates);
      } else if (dateIndex !== -1 && findEvent === -1 && findQuestionAssigned === -1) {
        const checkOtherZones = checkEventsAndQuestions(date);
        if (!checkOtherZones) {
          dates.splice(dateIndex, 1);
          setUpdatedWorkingDates(dates);
        }
      }
    } else {
      if (dateIndex === -1) {
        dates.push({ date });
        setUpdatedWorkingDates(dates);
      }
    }
  };

  const prevFormatEvent = (filteredEvents) => {
    return filteredEvents.reduce((arr, e) => {
      if (e) {
        const findVal = arr.findIndex((f) => f.value === e.title);
        if (findVal !== -1 && e) {
          arr[findVal].days.push(e.start);
        } else if (e) {
          arr.push({ value: e.title, label: e.title, location: e.location, days: [e.start] });
          if (filteredEvents.length === 0) return [];
        }
      }
      return arr;
    }, []);
  };

  const formatEvents = () => {
    if (events.length === 0 && selectedQuestionData.length === 0) return [];

    return events.reduce((arr, e) => {
      if (e) {
        const findVal = arr.findIndex((f) => f.date === e.start);
        let quesDate = selectedQuestionData.find((f) => f.date === e.start);
        selectedQuestionData.map((f) => {
          const findVal = arr.findIndex((s) => s.date === f.date);
          if (f.date !== e.start && findVal === -1) {
            arr.push({
              testTypes: [],
              date: f.date,
              question: f,
            });
          }
        });
        const newObj = { date: e.start, value: e.title, label: e.title, location: e.location };
        if (findVal !== -1) {
          arr[findVal].testTypes.push(newObj);
        } else {
          arr.push({
            testTypes: [newObj],
            date: e.start,
            ...(quesDate && { question: quesDate }),
          });
        }
      }
      return arr;
    }, []);
  };

  // const customDateCellWrapper = (props) => {
  //   const isDate = qaSchDates.includes(moment(props.value).format("YYYY-MM-DD"));
  //   const blocked = blockOutDate.find((f) => f.date === moment(props.value).format("YYYY-MM-DD"));

  //   return (
  //     <div className={`${props.children.props.className}`} style={{ backgroundColor: `${blocked && "#898483"}` }}>
  //       {isDate && (
  //         <div className="star-icon-wrapper ques-Calendar">
  //           <Status color="green" crossIcon type="circle" size="md" />
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const handleWorkingDate = (pickDate) => {
    const dates = [...workingDates];
    if (moment().startOf("day").isAfter(pickDate)) {
      setError("Cannot Update Previous dates");
      return;
    }
    const dateIndex = dates.findIndex((f) => f.date === pickDate);
    if (dateIndex !== -1) {
      dates.splice(dateIndex, 1);
    } else {
      dates.push({ date: pickDate });
    }
    setWorkingDates(dates);
    updateWorkingDays(pickDate, events, selectedQuestionData, dates);

    // try {
    //   updateBlockOutAPi(blockOutDate, dates);
    // } catch (err) {
    //   console.log("err", err.message);
    // }
  };

  const updateBlockOutAPi = useCallback(
    debounce((dates, workingDates) => {
      handleUpdateBlockOutDate(dates, workingDates);
    }, 500),
    []
  );

  const handleUpdateBlockOutDate = async (dates, workingDate) => {
    try {
      // await api.updateBlockOut(dates, workingDate);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const customDateCellWrapper = (props) => {
    const date = moment(props.value).format("YYYY-MM-DD");
    const isDate = selectedQuestionData.findIndex((q) => q.date === date);
    const cellEvents = events.filter((e) => e.start === date);

    let cellEventsVisible = cellEvents;
    let showMoreSpan = false;

    if (cellEvents.length > EVENTS_LIMIT_PER_CELL) {
      showMoreSpan = true;
      cellEventsVisible = [cellEvents[0], cellEvents[1]];
    } else {
      cellEventsVisible = cellEvents;
      showMoreSpan = false;
    }
    return (
      <div
        className={`${props.children.props.className}`}
        onClick={() => {
          handleSelectSlot({ start: props.value, end: moment(date).add(1, "day")._d });
        }}
      >
        {cellEventsVisible.map((e) => (
          <div
            style={{
              backgroundColor: `${removeSpaceIns(newProgram?.color || "#000")}`,
              border: `1px solid ${removeSpaceIns(newProgram?.color || "#000")}`,
              color: "white",
              borderRadius: "4px",
            }}
            onClick={(f) => {
              f.stopPropagation();
              handleSelectEvent(e);
            }}
            className="d-flex justify-content-between align-items-center my-1"
          >
            <span>{e.title}</span>
          </div>
        ))}
        {showMoreSpan && (
          <span
            className="cursor-pointer"
            style={{ color: "blue" }}
            onClick={(e) => {
              e.stopPropagation();
              setShowMorePopUp(true);
              setShowMoreDate(date);
            }}
          >
            Show more
          </span>
        )}
        <div
          className="star-icon-wrapper ques-Calendar ms-2"
          style={{ zIndex: "100000" }}
          onClick={(e) => {
            e.stopPropagation();
            handleQuestionClick(date);
          }}
        >
          {isDate !== -1 ? (
            <Status color="green" crossIcon type="circle" size="md" />
          ) : (
            <Status crossIcon type="circle" size="md" />
          )}
        </div>
      </div>
    );
  };

  const handleQuestionClick = (date) => {
    if (moment().startOf("day").isAfter(date)) {
      setError("Past schedule can not be modified");
      return;
    }

    if (!selectedQuestionGroup) {
      setError("Please select Question Group");
      return;
    }
    const findVal = selectedQuestionData.findIndex((q) => q.date === date);
    let temp = [...selectedQuestionData];
    if (findVal !== -1) {
      temp.splice(findVal, 1);
    } else {
      temp.push({ date });
    }
    setSelectedQuestionData(temp);

    updateWorkingDays(date, events, temp);
    // const findWorkingDate = workingDates.findIndex((f) => f.date === date);
    // if (findWorkingDate === -1) {
    //   const dates = [...updatedWorkingDates];
    //   const dateIndex = dates.findIndex((f) => f.date === date);
    //   if (dateIndex === -1) {
    //     dates.push({ date });
    //     setUpdatedWorkingDates(dates);
    //   } else {
    //     const findEvent = events.findIndex((e) => e.start === date);
    //     if (findEvent === -1) {
    //       dates.splice(dateIndex, 1);
    //       setUpdatedWorkingDates(dates);
    //     }
    //   }
    // }
  };

  const openUpdateWorkingDateConfirmation = () => {
    const nonWorkingDates = updatedWorkingDates.filter(
      (d) => workingDates.findIndex((wd) => wd.date === d.date) === -1
    );

    let displayDates = nonWorkingDates.map((d) => moment(d.date).format("MM/DD"));
    if (nonWorkingDates.length > 1) {
      const datesString = displayDates.join(", ");
      displayDates = setCharAt(datesString, datesString.lastIndexOf(","), " &");
    }

    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: `Working Dates`,
      // message: `You selected ${displayDates} as a testing day, currently these are not set as working days, would you like to do enable these days and schedule tests.`,
      message: `Following Non-Working dates ${displayDates} currently contains events/questions in them, would you like to do enable these days and schedule tests.`,
    });
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      handleConfirmUpdateWorkingDays(workingDates, objToSave, true);
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        handleConfirmUpdateWorkingDays(updatedWorkingDates);
        break;
    }
  };

  const handleConfirmUpdateWorkingDays = async (dates, dataToSave = objToSave, filterOutEvents) => {
    try {
      await api.updateBlockOut(blockOutDate, dates);
      const obj = dataToSave;
      if (obj) {
        if (filterOutEvents) {
          await handleRemoveEventsAndQuestions(obj);
          obj.testsData = obj.testsData.filter((test) => dates.findIndex((wd) => wd.date === test.date) !== -1);

          const filteredEvents = events.filter((e) => dates.findIndex((wd) => wd.date === e.start) !== -1);
          obj.testSchedule = prevFormatEvent(filteredEvents);
        }

        if (!programSetting) {
          let res;
          res = await api.newProgramSettings(obj, appContext.user);
          appContext.resetPrograms();

          handleConfirm(true, res);
        } else {
          handleSave(obj);
        }
      }
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage(error.message);
    }
  };

  const handleRemoveEventsAndQuestions = async (obj) => {
    const nonWorkingDates = updatedWorkingDates
      .filter((d) => workingDates.findIndex((wd) => wd.date === d.date) === -1)
      .map((m) => m.date);

    console.log("nonWorkingDates", nonWorkingDates);

    const zonesData = appContext.programs.filter(
      (program) =>
        obj?.id !== program.id &&
        program.testsData &&
        program.testsData.findIndex((f) => nonWorkingDates.includes(f.date)) !== -1
    );

    let testZones = [];
    if (zonesData.length > 0) {
      testZones = zonesData.map((test) => {
        const tests = test.testsData.filter((f) => !nonWorkingDates.includes(f.date));
        const schTets = test.testSchedule.map((sch) => {
          const days = sch.days.filter((f) => !nonWorkingDates.includes(f));
          return { ...sch, days };
        });
        return { ...test, testsData: tests, testSchedule: schTets };
      });
    }
    // if (quesSch.length > 0) {
    //   testQuesSch = quesSch.map((ques) => {
    //     const quesDates = ques.dayOptions.filter((f) => f !== date);
    //     return { ...ques, dayOptions: quesDates };
    //   });
    // }
    try {
      if (testZones.length > 0) {
        await api.updateZonesTests(testZones);
      }
      // if (testQuesSch.length > 0) {
      //   await api.quesSchedulesUpdate(testQuesSch);
      //   appContext.resetSchedules();
      //   // setQuesSchedules([]);
      // }

      // const dates = workingDates.filter((f) => f.date !== date);
      // updateBlockOutAPi(blockOutDate, dates);
      // setWorkingDates(dates);
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  useEffect(() => {
    if (JSON.stringify(workingDates) !== JSON.stringify(sortWorkingDays(workingDates)))
      setWorkingDates(sortWorkingDays(workingDates));

    if (JSON.stringify(updatedWorkingDates) !== JSON.stringify(sortWorkingDays(updatedWorkingDates)))
      setUpdatedWorkingDates(sortWorkingDays(updatedWorkingDates));
  }, [workingDates, updatedWorkingDates]);

  console.log("addTestType", addTestType);

  const handleSaveChange = async () => {
    let obj = { ...newProgram, isNew: selectItem ? false : true };
    const tests = formatEvents().sort((a, b) => (b.date < a.date ? 1 : a < b ? -1 : 0));
    if (!obj.color && obj.isNew) Object.assign(obj, { color: "#000000" });
    if (!obj.name || obj.name === " ") {
      setError(`${program} name is required`);
      return;
    }
    if (!obj.color || obj.color === "#ffffff") {
      setError("Please Select Color");
      return;
    }
    if (tests.length === 0 && testingCalendar) {
      setError("Please add at least one Event");
      return;
    }
    // if (
    //   !tests
    //     .filter((f) => moment().subtract(1, "day").endOf("day").isBefore(f.date))
    //     .every((e) => workingDates.find((f) => f.date === e.date))
    // ) {
    //   setError("");
    //   return;
    // }

    if (isExistName()) {
      setError(`${program} Name already exist`);
      return;
    }

    if (testingCalendar) {
      Object.assign(obj, {
        testsData: tests,
        testSchedule: prevFormatEvent(events),
        scheduleType: "month",
      });
    }

    Object.assign(obj, {
      questionsData: selectedQuestionData,
      scheduleID: selectedQuestionGroup?.value,
    });

    setObjToSave(obj);

    if (JSON.stringify(updatedWorkingDates) !== JSON.stringify(workingDates)) {
      openUpdateWorkingDateConfirmation();
      return;
    }
    if (JSON.stringify(workingDates) !== JSON.stringify(appContext.company.workingDays)) {
      handleConfirmUpdateWorkingDays(workingDates, obj);
      return;
    }

    if (!programSetting) {
      let res = null;
      try {
        res = await api.newProgramSettings(obj, appContext.user);
      } catch (err) {
        console.log(err.message);
      }

      handleConfirm(true, res);
    } else {
      handleSave(obj);
    }
  };

  const handleCloseModel = () => {
    if (programSetting) {
      handleClose();
    } else {
      handleConfirm(false, null);
    }
  };

  const checkEventsAndQuestions = (date) => {
    const zonesData = appContext.programs.filter(
      (program) => program.testsData && program.testsData.findIndex((f) => f.date === date) !== -1
    );
    // const quesSch = appContext.schedules.filter((s) => s.dayOptions?.includes(date));

    // console.log({ zonesData, quesSch });

    // if (zonesData.length > 0 || quesSch.length > 0) {
    if (zonesData.length > 0) {
      return true;
      // setQuesSchedules(quesSch);
      // setPickDate(pickDate);
      // setZones(zonesData);
      // setOpenConfirmation({
      //   isShow: true,
      //   actionType: CONFIRMATION_TYPE.UPDATE_SCHEDULE_BLOCK_OUT,
      //   title: `Remove Schedule`,
      //   message: `Would you like to modify the current testing or pre screening schedule and Working Day off on this date?`,
      // });
      // return;
    }
    return false;
  };

  const handleChange = () => {
    if (!addTestType.length > 0) {
      setError("Select Event Please!");
      return;
    }
    if (addDays <= 0) {
      setError("Select Days Positive Value Please!");
      return;
    }
    const findVal = addGroup.findIndex((c) => c.value === addTestType);
    if (findVal !== -1) {
      const groupData = [...addGroup];
      groupData.splice(findVal, 1, {
        value: addTestType,
        days: addDays,
        location: "On Location",
        label: addTestType,
      });
      setaddGroup(groupData);
    } else {
      setaddGroup((prevGroup) => [
        ...prevGroup,
        { value: addTestType, days: addDays, location: "On Location", label: addTestType },
      ]);
    }
    setAddtestType("");
    setAddDays(1);
  };

  const calendarStyle = (date) => {
    if (blockOutDate.find((f) => f.date === moment(date).format("YYYY-MM-DD"))) {
      return {
        style: {
          backgroundColor: "#898483",
          color: "white",
          margin: 0,
          padding: 0,
        },
      };
    }
  };

  const handleDelete = (index) => {
    const groupdata = [...addGroup];
    groupdata.splice(index, 1);
    setaddGroup(groupdata);
  };

  const getTestOrderQuantity = () => {
    const options = [];
    for (let i = 1; i <= 14; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  const TableRow = ({ data, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{TEST_TYPE_VALUE[data.value]}</td>
        <td>{`${data.days} ${data.days > 1 ? "days" : "day"}`}</td>
        <td>
          <i className="fas fa-trash" style={{ cursor: "pointer" }} onClick={() => handleDelete(index)} />
        </td>
      </tr>
    );
  };
  console.log("eventNames", eventNames);
  const handleSaveEvent = (opt) => {
    eventTypes.save([...eventNames, { label: opt.name, value: opt.name }]);
    showEventModal(false);
    setEventNames(eventTypes.get());
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => {
          programSetting ? handleClose() : handleConfirm(false, null);
        }}
        centered
        scrollable
        size={"xl"}
        className="showZoneModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
            {program} Setup
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="row mb-3"></div>
          <div className="row">
            <div className="col-sm-12 col-md-4 createClientsWrapper">
              <div className="form-group-wrapper  align-items-center">
                <label className="modalLineHeaders mt-0">Select Event: </label>
                <Select
                  options={eventNames}
                  className="assignSchedule"
                  value={addTestType.length > 0 ? { value: addTestType, label: addTestType } : null}
                  blurInputOnSelect={true}
                  isDisabled={selectItem}
                  defaultValue={null}
                  menuPlacement="bottom"
                  placeholder="Select Event"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 110000 }),
                  }}
                  onChange={(e) => {
                    setAddtestType(e.value);
                    setNewProgram({ ...newProgram, name: e.value });
                  }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-1 py-4  createClientsWrapper">
              <Button
                className="modalButtons btn-sm btn-dark btn-fill-dark rounded-0"
                variant="secondary"
                onClick={() => showEventModal(true)}
              >
                Add
              </Button>
            </div>

            {/* <div className="col-sm-12 col-md-3 createClientsWrapper">
              <div className="form-group-wrapper  align-items-center">
                <label className="modalLineHeaders mt-0">Select Location: </label>
                <Select
                  options={LOCATIONS_OPTIONS}
                  className="assignSchedule"
                  value={Object.keys(selectedLocation).length > 0 ? selectedLocation : null}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="bottom"
                  placeholder="Select Location"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  onChange={(e) => setSelectedLocation(e)}
                />
              </div>
            </div> */}
            {/* <div className="col-sm-12 col-md-4 createClientsWrapper">
              <div className="form-group-wrapper  align-items-center">
                <label className="modalLineHeaders mt-0">Assign Pre-Screening Schedule: </label>
                <Select
                  options={QuestionScheduleOptions}
                  className="assignSchedule"
                  value={quesSch || QuestionScheduleOptions.find((f) => f.value === newProgram?.scheduleID)}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="bottom"
                  placeholder="Select Group"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  onChange={(e) => setQuesSch(e)}
                />
              </div>
            </div> */}
            <div className="col-sm-12 col-md-3 createClientsWrapper">
              <div className="form-group-wrapper  align-items-center">
                <label className="modalLineHeaders mt-0">Question Groups: </label>
                <Select
                  options={questionGroupOptions}
                  className="assignSchedule"
                  value={selectedQuestionGroup || null}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="bottom"
                  placeholder="Select Group"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    setSelectedQuestionGroup(e || null);
                  }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-3 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">{program} Color:</label>
                <div ref={childNode} onClick={() => setShowPopover(!showPopover)} className="w-100 swatch zoneSwatch">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "2px",
                      cursor: "pointer",
                      backgroundColor: `${removeSpaceIns(newProgram?.color || "#000")}`,
                    }}
                  />
                </div>
                {showPopover && (
                  <Overlay
                    show={showPopover}
                    placement="auto"
                    onHide={() => setShowPopover(false)}
                    rootClose={true}
                    transition={true}
                    flip
                    target={childNode.current}
                  >
                    <Popover id="popover" className="popOverSchedule">
                      <SketchPicker
                        color={newProgram?.color || "#000"}
                        onChangeComplete={(e) => setNewProgram({ ...newProgram, color: e.hex })}
                      />
                    </Popover>
                  </Overlay>
                )}
              </div>
            </div>
            <div className="createClientsWrapper flex-wrap">
              <div className="first-last-name-wrapper">
                {!testingCalendar && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Duration:</label>
                    <div className="d-flex align-items-center justify-content-between">
                      <Select
                        options={getTestOrderQuantity()}
                        blurInputOnSelect={true}
                        defaultValue={{ value: 1, label: 1 }}
                        menuPlacement="bottom"
                        placeholder="Select Days"
                        className="w-100"
                        value={{ value: addDays, label: addDays }}
                        onChange={(e) => setAddDays(e.value)}
                      />

                      <Button
                        className="modalButtons btn-sm btn-dark btn-fill-dark rounded-0"
                        variant="secondary"
                        onClick={() => handleChange()}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {testingCalendar && (
            <div className="my-4 zone-modal-calendar" style={{ minHeight: "550pt" }}>
              <Calendar
                // events={events.map((m) => {
                //   return { ...m, title: TEST_TYPE_VALUE[m.title] };
                // })}
                startAccessor="start"
                endAccessor="end"
                defaultDate={moment().toDate()}
                localizer={localizer}
                views={["month"]}
                selectable
                components={{
                  // event: customEventContainer,
                  // eventWrapper: customEventContainer,
                  // eventContainerWrapper: customEventContainer,
                  dateHeader: customDateHeader,
                  dateCellWrapper: customDateCellWrapper,
                }}
                // dayPropGetter={calendarStyle}
                // onSelectEvent={handleSelectEvent}
                // onSelectSlot={handleSelectSlot}
                longPressThreshold={0}
                popup={true}
                eventPropGetter={(event, start, end, isSelected) => ({
                  event,
                  start,
                  end,
                  isSelected,
                  style: {
                    backgroundColor: `${removeSpaceIns(newProgram?.color || "#000")}`,
                  },
                })}
                // onShowMore={(event, date) => console.log("onShowMore", event, date)}
              />
            </div>
          )}
          {!testingCalendar && (
            <Row>
              <Col md="12">
                <Card className="strpied-tabled-with-hover modalHeight">
                  <Card.Body>
                    <div className="form-group-wrapper d-block">
                      <div className="table-responsive" style={{ overflow: "hidden" }}>
                        <Table className="table">
                          <thead>
                            <tr>
                              <th style={{ width: "10%" }}>SR#</th>
                              <th>Event</th>
                              <th>DURATION</th>
                            </tr>
                          </thead>
                          <tbody>
                            {addGroup.length > 0 &&
                              addGroup.map((data, index) => <TableRow data={data} key={index} index={index} />)}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={handleCloseModel}>
            Close
          </Button>
          <Button
            className="modalButtons headerButton btn-fill"
            variant="secondary"
            onClick={() => {
              handleSaveChange();
            }}
          >
            {selectItem ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          handleClose={() => setOpenConfirmation({ isShow: false })}
          closeBtn
        />
      )}

      {showMorePopUp && (
        <EventsPopOver
          cssClass={"eos-employeeModal"}
          setShowPopover={setShowMorePopUp}
          events={events}
          showMoreDate={showMoreDate}
          newProgram={newProgram}
          handleSelectEvent={handleSelectEvent}
        />
      )}
      {eventModal && (
        <EventModal
          handleClose={() => showEventModal(false)}
          selectItem={{}}
          handleSave={handleSaveEvent}
          locations={eventTypes.get()}
        />
      )}
    </>
  );
};

export default ZoneModalCalendar;
