import React, { useState, useContext, useRef, useMemo } from "react";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Overlay,
  Popover,
  Modal,
  Container,
} from "react-bootstrap";
import DashboardImage from "assets/img/dashboard.png";

const DashboardView = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="strpied-tabled-with-hover">
            <img src={DashboardImage} style={{ height: "87vh", width: "170vh" }} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardView;
