import React from "react";
import workingDayOnIcon from "assets/img/film.png";
import workingDayOffIcon from "assets/img/film-gray.png";

const WorkingDayCalendarSideBar = (props) => {
  const { workingDate, date, handleWorkingDate, isEventChecked } = props;
  return (
    <div className="calendar-detail-top my-2 mx-3">
      <span className="fw-bold">{isEventChecked || ""}</span>
      <span className="exclamation-mark ms-1 cursor-pointer" onClick={() => handleWorkingDate(date)}>
        {workingDate ? <i className="fas fa-building green-icon" /> : <i className="fas fa-building grey-icon" />}
      </span>
    </div>
  );
};

export default WorkingDayCalendarSideBar;
